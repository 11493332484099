.root {
  padding: 15px;
  border: 1px solid var(--gray-l);
  border-radius: 6px;
}

.add-quota {
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray-xl);
  margin: 10px 0;
}
