.body {
  margin-bottom: 45px;
}

.td {
  height: 55px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  padding: 10px;

  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);

  border-top: 1px solid var(--sec-01);
}

.rows {
  display: none;
}

@media (min-width: 425px) {
  .body {
    height: calc(100vh - var(--navbar-height) - 155px - 95px); /* 60-card header, 40-header, 45-footer, 20-margin-top, 75px below  */
    overflow-y: auto;
    margin-bottom: 0;
  }

  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
  }

  .rows {
    display: flex;
    margin-right: 25px;
  }
}