.body {
  margin-left: var(--left-offset);
}

@media (max-width: 450px) {
  .body {
    margin-left: 0;
  }
}

.link {
  color: var(--pri-02);
}

.link:hover {
  text-decoration: underline;
}

.details {
  margin-bottom: 30px;
}
