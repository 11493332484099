@value 800 from 'vars';

.root {
  display: none;
}

@media 800 {
  .root {
    display: flex;
  }
}

.container {
  position: relative;
}

.anchor {
  display: none;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  height: 60px;
  margin-left: 30px;
}

@media 800 {
  .anchor {
    display: flex;
  }
}

.dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 3;
  background-color: var(--pri-01);
  background-clip: padding-box;
  border: 1px solid var(--gray-l);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 1px 1px 2px 1px rgba(238, 238, 238, 1);
}

.visible {
  composes: dropdown;
  display: block;
}

.hidden {
  composes: dropdown;
  display: none;
}

.toggle {
  height: 60px;
  padding: 0;
  min-width: auto;
}

.toggle .avatar {
  width: 38px;
  margin: 0 auto;
}

.item ~ .item {
  border-top: 1px solid var(--gray-l);
}

.item:last-child {
  padding-bottom: 10px;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  min-width: 150px;
}

.menu-item {
  composes: item;
}

@media 800 {
  .menu-item:hover {
    background-color: var(--blue-l);
  }

  .menu-item:hover .item-name {
    color: var(--pri-02);
  }
}

.item-name {
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.profile {
  composes: item;
  height: auto;
  padding: 20px;
}

.avatar {

}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align: left;
}

.firstname {
  composes: item-name;
  margin-bottom: 10px;
}

.link {
  font-family: var(--font-reg);
  color: var(--pri-02);
  min-width: 100px;
}

.name {
  font-size: 11px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.account {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 15px;
  display: none;
}

@media 800 {
  .account {
    display: flex;
  }
}

.account > div {
  justify-content: flex-end;
  width: auto;
}

.icon {
  height: 34px;
  padding-bottom: 0px;
}