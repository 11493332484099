.items {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  display: flex;
  max-width: 650px;
}

@media(max-width: 425px){
  .item {
    max-width: 350px;
  }
}

.item {
  border-bottom: 1px solid var(--gray-l);
  padding: 20px 0;
}

.number {
  font-size: 18px;
  color: var(--black-l);
  font-family: var(--font-bold);
  margin-right: 10px;
}

.content {
  width: 600px;
}

.question {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 18px;
  margin-bottom: 15px;
}

.choice {
  display: flex;
  margin-bottom: 15px;
}

.checkbox {
  position: relative;
  height: 20px;
  width: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin: 0 25px 0 0;
  font-family: var(--font-helv), var(--font-reg), -apple-system, BlinkMacSystemFont, sans-serif;
}

@media(max-width: 425px){
  .checkbox {
    margin: 0 10px 0 0;
  }
}

.label {
  color: var(--black-l);
  font-size: 16px;
  padding-left: 10px;
  max-width: 510px;
}

.radio {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.todo:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  background-color: var(--pri-01);
  height: 20px;
  width: 20px;
  border: 1px solid var(--black-l);
  border-radius: 5px;
}

.pick:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '✓';
  color: var(--pri-01);
  background-color: var(--sec-05);
  height: 20px;
  width: 20px;
  border: 1px solid var(--black-l);
  border-radius: 5px;
}

.pass:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '✓';
  color: var(--pri-01);
  background-color: var(--sec-03);
  height: 20px;
  width: 20px;
  border: 1px solid var(--black-l);
  border-radius: 5px;
}

.fail:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '✗';
  color: var(--pri-01);
  background-color: var(--pri-04);
  height: 20px;
  width: 20px;
  border: 1px solid var(--black-l);
  border-radius: 5px;
  font-weight: 800;
  font-size: 22px;
}
