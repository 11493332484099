.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: var(--z-index-navbar);
  background-color: var(--pri-01);
  height: 60px;
  padding: 0 10px;
}

.col {
  composes: col from './AppBar.css';
}

.content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search {
  composes: col;
  display: flex;
  flex: 1;
  max-width: 400px;
  margin: 0 20px 0 30px;
}

.searchbar {
  display: block;
  width: 100%;
}

.nav {
  display: flex;
}

.dropdown {
  composes: col;
}

.navbar {
  display: none;
}

@media (min-width: 800px) {
  .navbar {
    display: flex;
  }
}

.logo {
  height: 40px;
  width: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.img {
  height: 40px;
  width: auto;
}

.loading {
  opacity: 0.3;
}

.landscape {
  height: 40px;
  width: 100px;
}