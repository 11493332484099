.section-root {

}

@media (min-width: 1100px) {
  .section-root {
    border-bottom: none;
  }
}

.wrap {
  margin: 40px auto 75px auto;
  max-width: 550px;
}

@media (min-width: 1100px) {
  .wrap {
    margin: 40px 0 75px 0;
    max-width: unset;
  }
}

.main {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

@media (min-width: 1100px) {
  .main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';

  margin-bottom: 50px;
}

@media (min-width: 800px) {
  .headline {
    margin-bottom: 25px;
    text-align: center;
  }
}

@media (min-width: 1100px) {
  .headline {
    text-align: unset;
  }
}

.how {
  max-width: 525px;
}

.items {

}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 35px;
}

.number {
  color: var(--pri-02);
  font-family: var(--font-bold);
  border-radius: 50%;
  background-color: var(--blue-l);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;

  margin-right: 30px;
}

.item-copy {
  composes: copy from '@/access/website/screens/Main/style.css';
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
  margin-bottom: 35px;
}

@media (min-width: 1100px) {
  .copy {
    display: none;
  }
}

.desktop-copy {
  composes: copy from '@/access/website/screens/Main/style.css';
  display: none;
}

@media (min-width: 1100px) {
  .desktop-copy {
    display: block;
    margin-bottom: 25px;
  }
}

.watch {

}

.caption {
  composes: caption from '@/access/website/screens/Main/style.css';

  margin-bottom: 35px;
  text-align: center;
}

@media (min-width: 1100px) {
  .caption {
    margin-bottom: 0;
  }
}

.box {
  position: relative;

  width: 100%;
  margin-bottom: 25px;
}

@media (min-width: 1100px) {
  .box {
    width: 530px;
    height: calc(530px / (16 / 9));
  }
}

@media (min-width: 1250px) {
  .box {
    width: 640px;
    height: calc(640px / (16 / 9));
  }
}

.controls {
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 1;
}

.playing,
.paused {
  composes: controls;
}

.hide {
  display: none;
}

.vbtn {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 65px;
  height: 65px;
}

.controls:hover .vbtn {
  background-color:  rgba(0, 0, 0, 0.5);
}

.play,
.pause {
  composes: vbtn;
}

.paused .pause,
.playing .pause {
  display: none;
}

.paused .play {
  display: flex;
}

.control {
  height: 35px;
  width: 35px;
  margin: 0 auto;

  stroke-width: 1;
  color: var(--pri-01);
  fill: var(--pri-01);
}

.play .control {
  padding-left: 5px;
}

.pause {
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
}

@media (min-width: 500px) {
  .playing:hover .pause {
    opacity: 1;
    display: flex;
  }
}

.video-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.poster-container {
  composes: video-container;
  opacity: 1;
  background-color: var(--pri-01);
}

.video {
  opacity: 0;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;

  height: 100%;
  margin: 0 auto;
}

.poster {
  composes: video;
  opacity: 1;
  background-color: var(--pri-01);
}

.player {
  margin: 0 auto;

  width: 100%;
  height: 100%;
}

@media (min-width: 500px) {
  .vbtn {
    height: 100px;
    width: 100px;
  }

  .control {
    height: 65px;
    width: 65px;
  }

  .play .control {
    padding-left: 10px;
  }
}