.root {

}

.question {

}

.add-question {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.header {
  background-color: var(--pri-01);
}

.first-divider {
  background-color: var(--gray-l);
}