@value 1000 from 'vars';
@value 800 from 'vars';

.root {
  width: 100vw;
}

@media 800 {
  .root {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
  }
}

.wrap {
  padding: 50px 45px;
  background-color: #f8f8f8;
}

@media 800 {
  .wrap {
    padding: 50px 35px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media 800 {
  .main {
    flex-wrap: nowrap;
  }
}

.title {
  color: var(--black);
  font-size: 22px;
  font-family: var(--font-bold);
  margin-bottom: 40px;
}

.item {
  width: 100%;
  min-height: 60px;
}

@media 800 {
  .item {
    min-height: auto;
  }
}

.link {
  display: flex;
  color: var(--pri-02);
  font-family: var(--font-semibold);
  line-height: 22px;
}

.link svg {
  stroke-width: 4;
  height: 22px;
  width: 22px;
}

.segment {
  margin-right: 10px;
  font-size: 18px;
}

@media 800 {
  .segment {
    /* flex: 1; */
  }
}

.arrow {
  width: 100px;
  height: 22px;
}

@media 1000 {
  .arrow {
    width: 30px;
  }
}