.root {
  margin-bottom: 20px;
}

.question {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.question .ordinal {
  color: var(--pri-02);
}

.answer {
  display: flex;
  align-items: center;
}

.answer .ordinal {
  color: var(--gray);
}

.ordinal {
  width: 40px;
  min-width: 40px;
  font-family: var(--font-semibold);
}

.condition {

}