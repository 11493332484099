.form {
  color: var(--black);
  background-color: var(--gray-xl);
  border-radius: 35px;
  box-sizing: border-box;
  height: 42px;
}

.wrap {
  display: flex;
  align-items: center;
  padding: 0 12px 0 9px;
  height: 100%;
}

.icon {
  color: #919191;
}

.input {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-family: var(--font-semibold);
  line-height: 22px;
  margin-left: 6px;
  text-overflow: ellipsis;
  transition: all 750ms ease;
  height: 100%;
  width: 100%;
}

.input::placeholder {
  color: #919191;
  opacity: 1;
}
