.root {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.wrap {
  width: 100%;
  padding: 10px 15px;
}

.main {
  display: flex;
  width: 100%;
}

.avatar {
  padding-right: 8px;
}

.launch {
  width: 100%;
}

.start {
  text-align: left;
  border-radius: 100px;
  border: 1px solid var(--sec-01);
  height: 40px;
  padding: 0 20px;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
  color: #6C6C6C;
}

.start:hover {
  background-color: rgba(229, 229, 229, 0.3);
  transition: background-color 100ms linear;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.btn {
  display: flex;
  align-items: center;
  font-size: 21;
  color: var(--black);
  height: 40px;
  width: 150px;
  border-radius: 5px;
}

.article,
.text,
.video {
  composes: btn;
}

.text:hover {
  background-color: #E8F0FE;
}

.video:hover {
  background-color: #FFE8E7;
}

.article:hover {
  background-color: #EDF9F5;
}

.icon {
  width: 23px;
  height: 23px;
  margin: 0 10px;
}

.video .icon {
  height: 28px;
  width: 28px;
  color: #F49690;
  fill: #F49690;
}

.text .icon {
  height: 19px;
  width: 19px;
  color: #84B3F7;
  fill: #84B3F7;
}

.article .icon {
  height: 28px;
  width: 28px;
  color: #32BF90;
  fill: #32BF90;
}

.btns > *:not(:first-child) {
  margin-left: 5px;
}
