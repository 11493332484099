.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.overflow-wrapper {
  max-width: 100%;
}