.root {
}

.wrap {
  max-width: 800px;
  padding: 40px 10px;
  margin: 0 auto;
}

.main {

}

.header {

}

.section {
  border-radius: 8px;
  border: 1px solid var(--sec-01);
  padding: 20px;
  margin-bottom: 20px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 20px;
}