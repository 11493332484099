@value 800, 1200 from 'vars';

.root {
  height: 100%;
}

.main {
  max-width: 1260px;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
}

@media 1200 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  position: relative;
}

@media 800 {
  .col:nth-of-type(1) {
    height: calc(100vh - var(--navbar-height) - 20px);
    margin: 0 20px;
  }
}

@media 1200 {
  .col:not(:nth-of-type(1)) {
    flex: 1 1 300px;
    min-width: 300px;
    max-width: 300px;
    margin: 0 20px;
  }

  .col {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
}