.root {
  border: 1px solid var(--gray-xl);
  width: 190px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.poster {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 20px;
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 15px;
  text-align: center;
}

.light-green {
  background-color: #CAEEE2;
}

.light-blue {
  background-color: #D4DFF8;
}

.light-red {
  background-color: #FBD3D2;
}

.custom {
  color: var(--pri-02);
}

.root:hover .title {
  /* color: var(--pri-02); */
}

.root:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}

.plus {
  color: var(--pri-02);
  height: 32px;
  width: 32px;
}

.net-promoter {
  background-color: #CFEFFF;
}

.employee-satisfaction {
  background-color: #FFF3C5;
}

.pricing-strategy {
  background-color: #D8F4D9;
}

.mce {
  background-color: #F0D9F8;
}

.market-analysis {
  background-color: #FFE2C8;
}

.external {
  background-color: var(--gray-xl);
}