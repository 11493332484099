.details {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.timestamp {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 12px;
  line-height: 16px;
  margin-left: 15px;
}

.seek {
  cursor: pointer;
}

.seek:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.speaker {
  color: var(--pri-02);
  font-family: var(--font-bold);
}

.text {
  margin: 8px 0;
  font-family: var(---font-reg);
  line-height: 29.5px;
  cursor: text;
}