.wrap {
  padding-right: 10px;
}

.limit {
  display: flex;
  align-items: center;
}

.limit .value {
  width: 35px;
}

.limit .slash {
  margin: 0 5px;
}

.name {
  width: 100%;
  max-width: 450px;
}

.btns {
  margin-top: 15px;
}

.action {
  width: 160px;
}


.row {
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}

.title {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  width: 50px;
  margin-right: 10px;
}
