.root {
  margin: 50px 0;
  position: relative;
}

.wrap {

}

@media (min-width: 800px) {
  .wrap {
    display: flex;
    justify-content: space-between;
  }
}

.main {
  max-width: 425px;

  position: relative;
  z-index: 2;
}

@media (min-width: 800px) {
  .main {
    padding: 140px 0 200px 30px;
    margin: 0 15px 0 0;
  }
}

@media (min-width: 1100px) {
  .main {
    padding: 200px 0 200px 30px;
  }
}

.phone {
  position: relative;

  text-align: center;
}

@media (min-width: 800px) {
  .phone {
    margin-top: 30px;
  }
}

.phone-img {
  width: 240px;
  z-index: 2;
  position: relative;

  margin-top: 30px;
  margin-bottom: 40px;

  border-radius: 36px;
  box-shadow: 11px 11px 40px 0 rgb(0 0 0 / 23%);
  background-color: var(--pri-01);
}

@media (min-width: 800px) {
  .phone-img {
    position: absolute;
    top: 0;
    margin-top: 0;
    margin-bottom: 0;
    right: 80px;
  }
}

@media (min-width: 1100px) {
  .phone-img {
    width: 275px;
    right: 125px;
  }
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';

  margin-top: 25px;
}

@media (min-width: 800px) {
  .copy {
    margin: 40px 0 50px 0;
  }
}

.blue-dots {
  display: none;

  position: absolute;
  left: 0;
  top: 0;

  max-width: 225px;
}

@media (min-width: 800px) {
  .blue-dots {
    display: block;
    max-width: 300px;
  }
}

@media (min-width: 1100px) {
  .blue-dots {
    max-width: 325px;
  }
}

.store-links {
  display: none;
}

.mobile-store-links {

}

@media (min-width: 800px) {
  .store-links {
    display: block;
  }

  .mobile-store-links {
    display: none;
  }
}

.store-link {
  margin-right: 20px;
}

.store-link:last-of-type {
  margin-right: 0;
}

.store-img {
  width: 120px;
  height: 35px;
}

@media (min-width: 1100px) {
  .store-img {
    width: 170px;
    height: 50px;
  }
}

.confetti {
  display: none;
}

.confetti-tablet {
  display: none;
}

.confetti-mobile {
  position: absolute;
  right: 0;
  top: 0;

  width: 100%;
  max-width: 400px;
}

@media (min-width: 800px) {
  .confetti-tablet {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 575px;
  }

  .confetti-mobile {
    display: none;
  }

  .phone {
    width: 100%;
    max-width: 575px;
  }
}

@media (min-width: 1100px) {
  .confetti-tablet {
    display: none;
  }

  .confetti {
    display: block;
    max-width: 750px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .phone {
    max-width: 750px;
  }
}