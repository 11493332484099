.root {
  margin-top: 40px;
}

.wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  padding: 0 10px;
}

@media(min-width: 600px) {
  .wrap {
    padding: 0 15px;
    max-width: 500px;
  }
}


@media(min-width: 800px) {
  .wrap {
    max-width: 650px;
  }
}

.body {
  margin-bottom: 25px;
  min-height: 250px
}

.screen {
  padding-top: 30px;
}

@media(max-width: 425px){
  .screen {
      padding-top: 50px;
  }
}

.hourly-input {
  composes: screen;
  width: 250px;
  color: var(--gray-d);
}

.seniority {
  composes: screen;
  width: 300px;
}

.hourly-rate-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hourly-rate-title {
  font-family: var(--font-bold);
  font-size: 19px;
}

.hourly-rate {
  margin-left: 10px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 19px;
}

.rate-progress-bar {
  width: 300px;
  margin-top: 50px;
  position: relative;
}

@media (max-width: 450px) {
  .rate-progress-bar {
      width: 250px;
  }
}

.signup-rate {
  margin-top: 20px;
  color: var(--pri-02);
  font-family: var(--font-semibold);
}

.top-border {
  border-top: 1px solid var(--gray-xl);
}