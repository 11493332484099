.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 10px;
  background-color: var(--gray-xl);
  min-height: 100%;
}

.page {
  margin: 0 20px;
  border: 9px solid transparent;
  background-clip: content-box;
  background-color: var(--pri-01);
  border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAA1ElEQVQ4jbWUWw6EIAxFy2NFs/8NzR4UJhpqLsdi5mOmSSMUOfYWqv3S0gMr4XlYH/64gZa/gN3ANYA7KAXALt4ktoQ5MI9YxqaG8bWmsIysMuT6piSQCa4whZThCu8CM4zP9YJaKci9jicPq3NcBWYoPMGUlhG7ivtkB+gVyFY75wXghOvh8t5mto1Mdim6e+MBqH6XsY+YAwjpq3vGF7weTWQptLEDVCZvPTMl5JZZsdh47FHW6qFMyvLYqjcnmdFfY9Xk/KDOlzCusX2mi/ofM7MPkzBcSp4Q1/wAAAAASUVORK5CYII=) 9 9;
}

.progress {
  height: 100%;
  width: 100%;
  background-color: var(--gray-xl);
}

.page-loader {
  height: 100%;
  width: 100%;
  background-color: var(--gray-xl);
}