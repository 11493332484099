.root {
  margin-top: 40px;
}

.wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  padding: 0 10px;
}

@media(min-width: 600px) {
  .wrap {
    padding: 0 15px;
    max-width: 500px;
  }
}

@media(min-width: 800px) {
  .wrap {
    max-width: 650px;
  }
}

.body {
  margin-bottom: 25px;
  min-height: 250px
}

.btn {
  color: var(--pri-02);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  padding: 2px 0;
}

.btn-current {
  composes: btn;
  margin: 20px 0 0 0;
}

.btn-prev {
  composes: btn;
  margin: 20px 0 70px 0;
}

.plus {
  margin-right: 10px;
}

.bio {
  padding: 30px 0 0 0;
}

.body-wrap {
  border-top: 1px solid var(--gray-xl);
}