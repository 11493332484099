.root {
  text-align: center;

  font-family: var(--font-reg);
  color: var(--gray-d);
  margin: 25px 0;
}

.icon {
  margin: 0 auto;
  width: 130px;
  height: auto;
  stroke-width: .5;
}

.text {
  margin-top: 40px;
}

.title {
  margin-top: 30px;
  font-family: var(--font-semibold);
}