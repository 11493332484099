.root {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  height: 80px;
}

.not-available {
  color: var(--gray-d);
  font-family: var(--font-reg);
  width: 100%;
  margin-left: 60px;
}