.placeholder {
  color: var(--gray);
}

.screener-ordinal {
  margin-right: 8px;
}

.add {
  margin-top: 25px;
}