@value 800, 1000 from 'vars';

.root {
  background-color: var(--gray-xl);
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  max-width: 400px;
}

@media 1000 {
  .main {
    margin: 30px 0;
    max-width: inherit;
  }
}

.item {
  width: 50%;
  text-align: center;
  margin-bottom: 30px;
}

@media 800 {
  .item {
    width: 180px;
  }
}

.item:last-child {
  display: none;
}

@media 1000 {
  .item:last-child {
    display: block;
  }
}

.img {
  width: 110px;
}

.title {
  font-size: 30px;
  font-family: var(--font-semibold);
  color: #1d1d1d;
  text-align: center;
  margin: 10px 0;
}