.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pri-01);
  height: 250px;
  width: 100%;
  max-width: 400px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 25px;
  height: 100%;
  width: 100%;
}

.row:not(:last-child) {
  margin-bottom: 20px;;
  width: 100%;
}

.label {
  font-size: 15px;
  font-family: var(--font-bold);
  color: var(--black);
}

.input {
  height: 32px;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 0;
  min-width: 75px;
  border-radius: 4px;
  font-size: 14px;
}

.btn:last-child {
  margin-left: 10px;
}

.popper {
  min-width: 275px;
  border: 1px solid var(--gray-l);
  padding: 20px;
  border-radius: 4px;
  background-color: var(--pri-01);
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  font-family: var(--font-reg);
}

.display {
  font-family: var(--font-semibold);
}

.link {
  color: var(--pri-02);
}

.close {
  cursor: pointer;
}