.root {
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 600px) {
  .root {
    width: 550px;
  }
}

.wrap {
  padding: 40px 0 50px 0;
}

.scroll {
  overflow: auto;
  overflow-y: auto;
  max-height: 72vh;
  margin-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

@media(max-width: 425px){
  .scroll {
    max-height: 65vh;
  }
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.4);
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  margin-bottom: 20px;
}

.copy {
  margin-bottom: 25px;
}

.subtitle {
  font-family: var(--font-semibold);
  font-size: 20px;
}

.navigation {
  margin-top: 25px;
  text-align: center;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 15px;
}

.checkbox {
  margin-top: 25px;
}

.submit {
  width: 100%;
}

@media (min-width: 800px) {
  .submit {
    width: 150px;
  }
}