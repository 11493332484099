.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

h1.title {
  color: var(--gray-d);
  font-size: 16px;
  font-family: var(--font-bold);
  line-height: 24px;
}

.method {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}

.logo {
  color: var(--gray-d);
  margin-right: 12px;
  width: auto;
  height: 37px;
}

.account {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
}

.number,
.currency {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-bold);
}

.number {
  margin-right: 14px;
}

.name {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}

.verified {
  color: var(--pri-03);
  font-size: 14px;
  margin: 0 0 0 12px;
}

.notverified {
  color: var(--sec-04);
  font-size: 14px;
  margin: 0 0 0 12px;
}