.root {
  min-height: 60px;
  font-size: 16px;
  border-bottom: 1px solid var(--gray-xl);
  color: var(--gray-d);
}

.wrap {
  display: flex;
  align-items: flex-start;
  padding: 17px 15px;
}

.option-wrap {
  padding: 15px 25px;
}

.textblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  margin-left: 15px;
  overflow: hidden;
}

.comment {
  overflow: hidden;
}

.author:before {
  content: '- ';
}

.author {
  font-size: 14px;
  color: var(--black);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quote {
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: 15px;
}

.quote:before {
  content: '"';
}

.quote:after {
  content: '"';
}

.star {
  display: flex;
  flex-shrink: 0;
}

.baseballCard {
  display: inline;
}