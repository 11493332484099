.root {
  margin-top: 25px;
  padding-bottom: 50px;

  --desktop-card-width: 700px;
  --tablet-card-width: 600px;
  --mobile-card-width: 260px;
}

@media (min-width: 800px) {
  .root {
    margin-top: 75px;
  }
}

.wrap {
  position: relative;
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';
  margin-bottom: 25px;
}

@media (min-width: 800px) {
  .headline {
    width: 425px;
  }
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
}

@media (min-width: 800px) {
  .copy {
    width: 525px;
  }
}

@media (min-width: 1100px) {
  .copy {
    width: 600px;
  }
}

.dots {
  display: none;
}

@media (min-width: 900px) {
  .dots {
    display: block;
    position: absolute;
    right: 0;
    top: -50px;
    width: 325px;
  }
}

@media (min-width: 900px) {
  .dots {
    top: 0;
  }
}

.products {
  margin-top: 40px;
}

@media (min-width: 800px) {
  .products {
    margin-top: 50px;
  }
}

.mobile-carousel-root {

}

.tablet-carousel-root {
  display: none;
}

.product-list-root {
  display: none;
}

@media (min-width: 800px) {
  .mobile-carousel-root {
    display: none;
  }

  .tablet-carousel-root {
    display: block;
  }
}

@media (min-width: 1100px) {
  .tablet-carousel-root {
    display: none;
  }

  .product-list-root {
    display: block;
  }
}

.product-list-wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

@media (min-width: 1100px) {
  .product-list-wrap {
    justify-content: flex-start;
  }
}

.product-list-background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 880px;
}

@media (min-width: 1300px) {
  .product-list-background {
    display: block;
  }
}

.product-list-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: calc(75px + var(--desktop-card-width));
  padding: 10px 0;
}

@media (min-width: 1300px) {
  .product-list-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: calc(75px + var(--desktop-card-width) + 140px);
    padding: 10px 0;
  }
}

.product-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 240px;
  border-radius: 12px;
  padding: 13px 10px;
  margin-bottom: 5px;
}

.product-list-item:last-of-type {
  margin-bottom: 0;
}

.product-list-item:hover, .active-product-list-item {
  background-color: var(--blue-l);
}

.product-list-item:hover .product-list-icon,
.product-list-item:hover .product-list-name,
.active-product-list-item .product-list-icon,
.active-product-list-item .product-list-name {
  color: var(--pri-02);
}

.product-list-icon {
  color: var(--gray-d);
  margin-right: 20px;
}

.product-list-name {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 22px;
}

.carousel-items {
  display: flex;
}

.carousel-wrap {
  text-align: center;
  display: flex;
  margin: 0 auto;

  overflow-x: hidden;
  padding: 5px;
}

.mobile-carousel-wrap {
  width: var(--mobile-card-width);
}

.tablet-carousel-wrap {
  margin-right: -35px;
}

.card {
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, .28);
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
}

.product-list-card-container {
  position: relative;
}

@media (min-width: 1300px) {
  .product-list-card-container {
    margin-left: 30px;
  }
}

.product-list-card {
  composes: card;
  display: block;
  width: var(--desktop-card-width);
  height: 441px;
  background-color: var(--pri-01);
  margin: 0 auto;
  z-index: 2;

  position: absolute;
}

@media (min-width: 1300px) {
  .product-list-card {
    margin-left: 140px;
  }
}

.product-list-card .product-img {
  width: var(--desktop-card-width);
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
}

.tablet-carousel-card .product-img {
  width: var(--tablet-card-width);
}

.product-img-mobile {
  width: var(--mobile-card-width);
}

.transparent {
  opacity: 0;
  z-index: 0;
  height: 0;
}

.tablet-carousel-card {
  composes: card;
  display: flex;
  position: relative;
  width: var(--tablet-card-width);
  margin: 0 auto;
}

.mobile-carousel-card {
  composes: card;
  display: flex;
  position: relative;
  margin: 0 auto;
  width: var(--mobile-card-width);
}

.name-tag {
  display: flex;
  align-items: center;
  background-color: var(--sec-05);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;

  width: 150px;
  padding: 10px;

  position: absolute;
  top: 0;
  left: 0;
}

.name-tag-icon {
  color: var(--pri-01);
  margin-right: 15px;
}

.name-tag-name {
  color: var(--pri-01);
  font-family: var(--font-semibold);
}

.carousel-card {
  transition: .6s;
  margin-right: 30px;
}

.carousel-card:last-of-type {
  margin-right: 0;
}

.carousel-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.carousel-step {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 20px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid var(--pri-01);
}

.carousel-step:last-of-type {
  margin: 0;
}

.carousel-step-inner {
  background-color: var(--gray-d);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.active-carousel-step {
  border: 2px solid var(--pri-02);
}

.active-carousel-step .carousel-step-inner{
  background-color: var(--pri-02);
  border: 1px solid var(--pri-02);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(10, 104, 239, .3);
  }

  70% {
    box-shadow: 0 0 0 1rem rgba(242,47,70,0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(242,47,70,0);
  }
}

.help-container {
  position: absolute;
  z-index: 3;
  cursor: default;
}

.help {
  transition: background-color .3s;
  animation: pulse 3s infinite;

  background-color: rgba(10, 104, 239, .3);
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.help-container:hover .help {
  background-color: rgba(10, 104, 239, .6);
  animation: none;
  transition: none;
}

.help-container:hover  .help-inner {
  background-color: var(--pri-02);
}

.help-inner {
  position: absolute;
  top: 10px;
  left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgba(10, 104, 239, .6);
  color: var(--pri-01);
  font-size: 18px;
  font-family: var(--font-semibold);
}

.transcripts-help-one {
  top: 35px;
  right: 185px;
}

.transcripts-help-two {
  top: 235px;
  right: 185px;
}

.search-help-one {
  left: 140px;
  top: 150px;
}

.search-help-two {
  top: 40px;
  right: 275px;
}

.scheduling-help-one {
  top: 35px;
  left: 10px;
}

.scheduling-help-two {
  right: 275px;
  top: 150px;
}

.profile-help-one {
  top: 125px;
  right: 325px;
}

.profile-help-two {
  top: 310px;
  right: 300px;
}

.messaging-help-one {
  top: 75px;
  right: 230px;
}

.conferencing-help-one {
  top: 150px;
  right: 280px;
}

.conferencing-help-two {
  top: 350px;
  right: 225px;
}

.surveys-help-one {
  top: 340px;
  left: 250px;
}

.surveys-help-two {
  top: 170px;
  right: 200px;
}