.root {

}

.container {

}

.content {
  min-height: 240px;
}

@media (min-width: 800px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.original-image-container {
  text-align: center;
  height: 280px;
}

.cropped-image-container {
  text-align: center;
  width: 100%;
}

@media (max-width: 800px) {
  .cropped-image-container {
    height: 150px;
  }
}

.file-picker {
  margin: 15px 0;
}

.original-image {
  max-height: 230px;
  max-width: 230px;
}

.cropped-image {
  height: 90px;
  width: 90px;
}

.footer {
  text-align: center;
  margin-top: 25px;
}

.preview-text {
  margin-bottom: 10px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 18px;
}

.preview-top {
  height: 20px;
}

.warning {
  height: 20px;
  color: var(--pri-04);
}

.btn {
  width: 150px;
}

.input {
  position: relative;
  width: 110px;
  background-color: var(--sec-01);
  height: 30px;
  border-radius: 4px;
}

.input input {
  position: relative;
  width: 100%;
  height: 30px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.span {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  z-index: 0;
  font-family: var(--font-reg);
}

.placeholder {
  background-color: var(--gray-xl);
}