.root {
  display: flex;
  align-items: center;

  width: 100%;
}

.player {
  margin-right: 15px;
}

@media(max-width: 425px) {
  .duration {
    display: none;
  }
}

.download {
  cursor: pointer;
  margin-left: 20px;
  color: var(--black-l);
}

.download:hover {
  color: var(--pri-02);
}

@media(max-width: 425px) {
  .download {
    display: none;
  }
}