.root {

}

.label {
  margin-right: 25px;
  color: var(--black-l);
  width: 110px;
}

.row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.checkbox {
  margin-top: 20px;
  color: var(--black-l);
}

.label-input {
  display: flex;
  flex-grow: 1;
}

.input {
  width: 100px;
}

/* Chrome, Safari, Edge, Opera */
.root input::-webkit-outer-spin-button,
.root input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.root input[type=number] {
  -moz-appearance: textfield;
}