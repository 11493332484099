.root {
  /* overflow: hidden; */
}

.wrap {
  display: flex;
  position: relative;
}

.scrollable {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.tabs {
  display: inline-block;
  position: relative;
}

.tab {
  display: inline-block;
  width: 180px;
  font-size: 16px;
  line-height: 45px;
  min-height: 45px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--gray-d);
  cursor: pointer;
}

.active {
  composes: tab;
  color: var(--pri-02);
}

.bar {
  height: 3px;
  background-color: var(--sec-01);
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
}

.indicator {
  position: absolute;
  height: 4px;
  background-color: var(--pri-02);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
  bottom: 0;
}