.root {
  display: grid;
  grid-template-columns: 120px 110px auto;
  grid-template-rows: auto;
  gap: 6px 10px;
}

.header {
  font-family: var(--font-bold);
  font-size: 14px;
}

.selected {
  display: inline-flex;
  align-items: center;
  background-color: var(--gray-l);
  border-radius: 4px;
  font-size: 14px;
}

.selected-text {
  padding: 4px 6px;
}

.selected-remove {
  font-family: var(--font-bold);
  cursor: pointer;
  padding: 4px 8px;
}

.selected-remove:hover {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--pri-01);
  background-color: var(--pri-04);
  opacity: 0.7;
}