.root {
  display: flex;
  align-items: center;
}

.down {
  margin-left: 20px;
}

.thumb {
  color: var(--gray-d);
}

.active {
  color: var(--pri-02);
  fill: var(--blue-l);
}

.inactive {
  cursor: pointer;
}

.inactive:hover {
  color: var(--sec-05);
}