.root {
  --border-radius: 5px;
  width: 100%;
}

.header, .row, .footer {
  display: flex;
}

.row {
  align-items: center;
}

.footer {
  border-top: 1px solid var(--gray-xl);
}

.row:nth-of-type(odd) {
  background-color: var(--gray-xl);
}

.row:first-of-type {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

.row:last-of-type {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.row-name {
  flex-grow: 1;
  word-break: break-word;
  padding: 10px;

  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.header .cell {
  font-family: var(--font-semibold);
}

.cell {
  display: flex;
  padding: 10px;
  word-break: break-word;
  box-sizing: border-box;
}

.input {
  width: 90px;
}