.root {
  padding: 15px;
  padding-top: 20px;
  border-bottom: 1px solid var(--gray-xl);
}

.root:last-child {
  border-bottom: none;
}

.top {

}

@media (min-width: 1200px) {
  .top {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 15px;
}

.title:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.timestamp {
  color: var(--gray-d);
  font-size: 12px;
  width: 125px;
  text-align: right;
}

@media (max-width: 1200px) {
  .timestamp {
    margin: 10px 0 15px 0;
    text-align: left;
  }
}

.keywords {
  composes: tags from 'static/css/tags.css';
  margin-top: 7px;
  padding: 0;
}

@media (min-width: 1200px) {
  .keywords {
    width: 90%;
  }
}

.tag {
  margin: 5px 10px 5px 0;
}

.tag:last-of-type {
  margin-right: 0;
}

.search-all {
  color: var(--gray-d);
  font-size: 12px;
  font-family: var(--font-semibold);
  margin: 0 0 0 10px;
  width: 75px;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.search-all:hover {
  color: var(--pri-02);
}