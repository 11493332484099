.root {
  width: 100%;
}

.input {
    composes: input from 'css/input.css';

    width: 100%;
    font-size: 18px;
    line-height: 18px;
    font-family: var(--font-reg);
    color: var(--gray-d);

    cursor: pointer;

    border-bottom: 2px solid var(--gray-l);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input:focus {
    border: 0;
    box-shadow: 0;
    outline: none;
    border-bottom: 2px solid var(--gray-l);
}

.disabled {
  color: var(--gray-l);
  cursor: unset;
}

.arrowDown {
    padding-right: 10px;
    padding-bottom: 5px;
}

.arrowDown:after {
    display: block;
    padding-left: 10px;
    content: '\25BE';
}

.selected-text {
    font-family: var(--font-reg);
    font-size: 16px;
    padding: 0 0 5px 1px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
}

.list {
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgb(238 238 238);
  max-height: 300px;
  overflow-y: auto;
}

.item {
  padding: 10px 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-l);
}

.placeholder {
    color: var(--gray-l);
}