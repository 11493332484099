.entry {
  margin-bottom: 25px;
}

.entry:last-child {
  /* margin-bottom: 0; */
}

.read-only {
  display: flex;
  justify-content: flex-start;
}

.icon-column {
  margin-right: 15px;
}

@media (min-width: 800px) {
  .icon-column {
    margin-right: 30px;
  }
}
.main {
  width: 100%;
}

.columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.half {
  width: 100%;
}

@media (min-width: 800px) {
  .half {
    width: 50%;
  }
}

.field {
  margin-bottom: 20px;
  margin-right: 20px;
  font-family: var(--font-reg);
}

@media (min-width: 800px) {
  .field {
    margin-right: 50px;
  }
}

.field-readonly {
  composes: field;
  border: none;
  padding: 0;
}

.description {
  composes: field-readonly;
  margin: 10px 0;
  margin-right: 30px;

  cursor: default;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@media (min-width: 800px) {
  .description {
    max-width: 550px;
  }
}

.top-item{
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .top-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.header {
  font-family: var(--font-bold);
  color: var(--gray-d);
  font-size: 18px;
  margin-bottom: 5px;
}

.title {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 18px;
  margin-bottom: 5px;
  padding-right: 30px;
}

.subtitle {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 5px;
  padding-right: 30px;
}

@media (min-width: 800px) {
  .title, .subtitle {
    padding: 0;
  }
}

.tags {
  margin-top: 15px;
  max-width: 400px;
}

.cell {
  margin-bottom: 20px;
}

.validation {
  color: var(--pri-04);
  margin-top: 10px;
  max-width: 250px;
}


.desktop {
  display: none;
}

@media (min-width: 800px) {
  .desktop {
    display: unset;
  }

  .mobile {
    display: none;
  }
}

.min-height {
  min-height: 30px;
}

.link:hover {
  display: inline-flex;
  color: var(--pri-02);
  text-decoration: underline;
}