.vics {

}

.vics-container {

}

.vics-container-top {
    height: 280px;
}

@media(max-width: 450px){
    .vics-container-top {
        height: 150px;
    }
}

.vics-container-bottom {
    composes: staggered from 'scenes/vics-selection/Tags/style.css';
    align-content: flex-start;
}

.vics-subtext {
    composes: vics-subtext from 'scenes/vics-selection/style.css';
}

.warning {
    composes: warning from 'scenes/vics-selection/style.css';
}

.autocomplete {
    composes: autocomplete from 'scenes/vics-selection/style.css';
}

.tags {
    composes: tags from 'static/css/tags.css';
}

.selected {
    composes: selected from 'scenes/vics-selection/style.css';
}