.root {
  padding: 15px 0;
}

.form-btns {
  margin-top: 15px;
}

.question-text {
  margin-bottom: 20px;
}

.response {
  margin-top: 15px;
}

.tagging {
  margin: 0;
}