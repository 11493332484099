@value 800, 1100, width-navbar from 'vars';

.root {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 10px 40px;
}

.wrap {
  max-width: 750px;
  margin: 0 auto;
}

.main {
  padding: 10px 0;
  color: var(--black-l);
  margin: 0 auto;
  max-width: 900px;
}

@media 800 {
  .main {
    display: flex;
    justify-content: space-between;
  }
}

.row {
  margin-bottom: 20px;
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
}

@media 1100 {
  .link {
    padding: 0;
    height: 80px;
  }
}

.btns {
  padding: 20px 0;
  max-width: 360px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.btn {
  width: 150px;
}

.label {
  font-size: 16px;
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.field {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 22px;
  font-family: var(--font-reg);
  font-size: 16px;
  color: var(--gray-d);
}

.tags {
  composes: tags from 'static/css/tags.css';
  padding: 8px 0;
}

.survey-placeholder {
  color: var(--gray);
}

.left {
  width: 100%;
}

.divider {
  display: none;
}

@media 800 {
  .divider {
    display: block;
    border-left: 1px solid var(--gray-xl);
    margin: 60px 50px 60px 60px;
  }
}

.right {
  display: none;
}

@media 800 {
  .right {
    display: flex;
    align-items: center;
    min-width: 210px;
    width: 210px;
  }
}

.consent {
  min-height: 120px;
  max-height: 120px;
}

.recording {

}

.spinner {
  padding: 20px 0;
}

.external-warning {
  color: var(--gray);
}

.theme {
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}

.theme .wrap {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  max-width: 600px;
}

@media (min-width: width-navbar) {
  .theme .wrap {
    margin: 0;
  }
}

.theme .main {
  display: flex;
  justify-content: center;
  max-width: auto;
  padding-top: 50px;
}

.theme .consent {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.theme .btns {
  justify-content: space-between;
  width: 330px;
  margin: 0;
}

@media 800 {
  .theme .consent {
    justify-content: flex-start;
  }
}