.root {

}

.add-section {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.divider {
  margin: 15px 0;
}