.root {
  composes: content from '../../style.css';
}

.header {
  border-bottom: 1px solid var(--sec-01);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h1.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 28px;
  line-height: 54px;
}

.section {
  padding: 14px 0;
  margin-bottom: 30px;
  min-height: 218px;
}

.section:last-child {
  margin-bottom: 50px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
