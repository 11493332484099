.modal-root {
  max-width: 550px;
}

.modal-wrap {
  padding: 20px 40px;
}

.header {
  text-align: left;
  margin-bottom: 25px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.section {
  margin-bottom: 25px;
}

.subtitle {
  margin: 20px 0;
}

.btn {
  width: 175px;
}

.footer {
  text-align: center;
}

.payment {
  position: relative;
  height: 60px;
  box-sizing: border-box;
}

.card {
  padding: 10px;
  border: 1px solid var(--gray-xl);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit {
  cursor: pointer;
  font-size: 14px;
  color: var(--pri-02);
}

.edit:hover {
  color: var(--pri-02);
}

.avatar {
  margin-right: 15px;
}

.recipient {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-family: var(--font-semibold);
}