.root {
  padding-bottom: 60px;
  border-top: 1px solid var(--gray-xl);
}
.placeholder {
  color: var(--gray-d);
  min-height: 100px;
  padding-top: 25px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}