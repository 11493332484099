.root {
  overflow-y: unset;
}

.select {
  margin-bottom: 50px;
}

.row {
  padding: 15px 0;
  text-align: left;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: center;
}