.root {
  /* padding: 0 25px; */
}

.logo {
  height: 40px;
  width: auto;
  margin: 10px 0;
  padding-left: 25px;
}

.wrap {
  max-width: 420px;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .wrap {
    max-width: 1060px;
  }
}

.header {
  padding-top: 30px;
  text-align: center;
}

@media (min-width: 800px) {
  .header {
    padding: 50px 0;
  }
}

.title {
  font-family: var(--font-bold);
  font-size: 34px;
  color: var(--black-l);
  margin-bottom: 20px;
}

.subtitle {
  font-family: var(--font-bold);
  font-size: 16px;
  color: var(--black-l);
  margin-bottom: 30px;
}

@media (min-width: 800px) {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field {
  margin-top: 50px;
  width: 90%;
}

@media (min-width: 800px) {
  .field {
    width: 100%;
    width: 320px;
  }
}

.link {
  composes: subtitle;
  color: var(--pri-02);
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pri-02);
  border-radius: 11px;
  min-width: 260px;
  height: 55px;
}

.text {
  color: var(--pri-01);
  margin: 0 15px;
  font-family: var(--font-bold);
  font-size: 18px;
}

.forgot {
  composes: link;
  text-align: right;
  margin-top: 10px;
}

.notify {
  position: absolute;
  text-align: center;
  top: 14px;
  left: 0;
  right: 0;
  height: 14px;
}

.error {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-reg);
  color: var(--pri-04);
}

.input {
  composes: input from 'css/input.css';
}