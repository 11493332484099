.root {
  position: relative;
}

.wrap {
  overflow: auto;
  white-space: nowrap;
}

.chip {
  border-radius: 28px;
  overflow: hidden;
}

.chip:not(:last-child) {
  margin-right: 10px;
}

.main {
  display: inline-block;
  padding: 8px 10px;
  font-size: 14px;
  font-family: var(--font-semibold);
  line-height: 22px;
}

.active,
.active:hover {
  color: var(--pri-02);
  background-color: var(--blue-l);
  border-color: #a8c8ff;
}