@value 800 from 'vars';

.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--navbar-height));
  position: relative;
}

@media 800 {
  .root {
    position: relative;
    min-height: calc(100vh - var(--navbar-height) - 40px);
  }
}

.wrap {
  margin: 0 auto;
  padding: 20px 15px;
  max-width: 650px;
}

@media 800 {
  .wrap {
    position: relative;
  }
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);

  font-size: 22px;
  margin-bottom: 20px;
}

@media (min-width: 500px) {
  .title {
    font-size: 28px;
  }
}

@media (min-width: 800px) {
  .title {
    margin: 20px 0 30px 0;
    font-size: 32px;
  }
}

.line {
  font-size: 14px;
  color: var(--black-l);
}

@media (min-width: 500px) {
  .line {
    font-size: 18px;
  }
}

@media (min-width: 800px) {
  .line {
    font-size: 20px;
  }
}

.line-one {
  composes: line;
  margin-bottom: 25px;
  line-height: 30px;
}

.line-two {
  composes: line;
  display: none;
}

.line-two-mobile {
  composes: line;
}

@media (min-width: 800px) {
  .line-two {
    display: block;
  }

  .line-two-mobile {
    display: none;
  }
}


.screenshot {
  margin-bottom: 30px;
  margin-top: 15px;
  text-align: center;

  height: 350px;
}

@media (min-width: 800px) {
  .screenshot {
    margin: 20px 0 40px 0;
  }
}

.img {
  display: none;
}

@media (min-width: 800px) {
  .img {
    display: block;
    height: 350px;
  }
}

.mobile-img {
  height: 350px;
}

@media (min-width: 500px) {
  .mobile-img {
    height: 480px;
  }
}

@media (min-width: 800px) {
  .mobile-img {
    display: none;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.done {
  position: absolute;
  bottom: 15px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  left: 30px;
  right: 30px;
}

@media 800 {
  .done {
    bottom: unset;
    left: unset;
    max-width: 145px;
  }
}

.bottom {
  width: 100%;
  text-align: center;
}

@media 800 {
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 145px;
  }
}

.btn {
  background-color: var(--pri-03);
  border: 2px solid var(--pri-03);
  height: 55px;
  width: 100%;
}

.btn:hover {
  background-color: var(--pri-03);
  border: 2px solid var(--pri-03);
}

@media 800 {
  .btn {
    height: 45px;
  }
}

.hidden {
  visibility: hidden;
}