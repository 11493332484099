.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.row {
  margin: 0 0 30px 0;
}

.row:last-child {
  margin: 0;
}

.active {
  composes: active from '../style.css';
}

.icon {
  composes: icon from '../style.css';
  height: 40px;
  width: 40px;
}

.icon svg {
  height: 32px;
  width: 32px;
}

.top {
}

.bottom {
  padding: 10px 0;
}

.bottom > div {
  margin-bottom: 30px;
}