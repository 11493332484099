.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

h1.title {
  color: var(--gray-d);
  font-size: 16px;
  font-family: var(--font-bold);
  line-height: 24px;
}

.method {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}

.logo {
  margin-right: 12px;
  width: 55px;
  height: auto;
}

.card {
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
}

.number {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-bold);
}

.expiry {
  color: var(--gray-m);
  font-size: 14px;
  font-family: var(--font-bold);
}

.number {
  margin-right: 22px;
}

.name {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}