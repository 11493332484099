.root {
  background-color: var(--gray-xl);
  text-align: center;

  color: var(--gray-d);
}

.truncated-root {
  composes: root;
  text-align: left;
}

.wrap {
  margin: 0 auto;
  padding: 30px;
  max-width: 350px;
  box-sizing: border-box;
}

@media (min-width: 800px) {
  .wrap {
    padding: 40px;
    max-width: 700px;
  }

  .tw {
    padding: 40px 15px;
  }
}

@media (min-width: 1100px) {
  .wrap {
    max-width: 1280px;
  }
}

.top {
  margin-bottom: 40px;
}

@media (min-width: 1100px) {
  .top {
    display: flex;
    justify-content: space-between;
  }
}

.logo {

}

.img {
  height: 30px;
}

.details {
  margin: 20px 0;
}

.address-line {
  display: none;
}

.email, .phone {
  display: block;
}

.separator {
  display: none;
}

@media (min-width: 800px) {
  .address-line-mobile {
    display: none;
  }

  .email:hover,
  .phone:hover,
  .address-line:hover {
    color: black;
    border-bottom: 1px solid var(--black);
  }

  .phone, .email {
    display: unset;
  }

  .address-line {
    display: inline;
    line-height: 24px;
  }

  .separator {
    display: unset;
  }
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 18px;
}

.site-col-one {
  display: flex;
  margin-bottom: 30px;
}

.site-col-two {
  display: flex;
}

@media (min-width: 1100px) {
  .site-col-two {
    display: unset;
    margin-left: 35px;
  }
}


.download {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
}

@media (min-width: 1100px) {
  .download {
    margin-top: 0;
  }
}

.store-img {
  width: 120px;
  height: 35px;
}


.download-title {
  composes: title;
  margin-bottom: 10px;
}

.download-text {
  margin-bottom: 30px;
  width: 290px;
}

@media (min-width: 800px) {
  .download-text {
    width: 350px;
  }
}

.store-links {

}

.store-link {
  margin-right: 10px;
}

.store-link:last-of-type {
  margin-right: 0;
}

.follow {
  display: flex;
  align-items: center;

  margin-bottom: 65px;
  height: 32px;
}

@media (min-width: 1100px) {
  .follow {
    margin-bottom: 0;
  }
}

.follow-title {
  composes: title;
  margin-right: 20px;
}

.social-links {
  display: flex;
  align-items: center;
}

.social-link {
  margin-right: 15px;

  background-color: var(--gray-d);
  border-radius: 50%;
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.social-link:last-of-type {
  margin-right: 0;
}

.social-link:hover {
  background-color: var(--pri-02);
}

.social-link:hover .youtube {
  color: var(--pri-02);
}

.social-icon {
  height: 16px;
  width: 16px;
}

.facebook {
  composes: social-icon;
  color: var(--pri-01);
  fill: var(--pri-01);
  stroke-width: 1;
}

.linkedin {
  composes: social-icon;
  color: var(--pri-01);
  fill: var(--pri-01);
  stroke-width: 1;
}

.twitter {
  composes: social-icon;
  color: var(--pri-01);
  fill: var(--pri-01);
  stroke-width: 1;
}

.youtube {
  composes: social-icon;
  fill: var(--pri-01);
}

.text {
  border-top: 1px solid var(--gray-l);
  padding-top: 10px;
  margin-top: 10px;
}
