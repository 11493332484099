.root {
  padding: 15px;
}

.buttonset {

}

.btn {
  composes: brick from 'css/button.css';
}

.cancel {
  composes: btn;
  color: var(--gray-d);
  border: 2px solid var(--gray-d);
}