.root {

}

.table {

}

.header {

}

.body {
  margin-bottom: 45px;
}

.td {
  height: 45px;
}

.name:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: flex-end;

  padding: 10px;

  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);

  border-top: 1px solid var(--gray-xl);
}

@media (min-width: 425px) {

  .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    border: 1px solid var(--gray-l);
  }

  .body {
    height: calc(100vh - var(--navbar-height) - 145px - 95px);
    overflow-y: auto;
    margin-bottom: 0;

    border-right: 1px solid var(--gray-xl);
    border-left: 1px solid var(--gray-xl);
  }

  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
    margin-bottom: 125px;

    border: 1px solid var(--gray-xl);
  }

}

.pagination {

}

.rows {
  margin-bottom: 15px;
  display: none;
}

@media (min-width: 425px) {
  .footer {

  }

  .pagination {

  }
  
  .rows {
    margin-right: 25px;
    margin-bottom: 0;
    display: flex;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  width: 120px;
}

.icon-bg {
  height: 35px;
  width: 35px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-bg:hover {
  background-color: var(--gray-xl);
}

.icon-wrap {
}

.icon {
  color: var(--gray-m);
  width: 20px;
  height: 100%;
}

.select {
  position: relative;
}

.alert {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: var(--pri-04);

  position: absolute;
  bottom: 0;
  right: 0;
}