.root {
  --thumb-diameter: 22px;
  --track-height: 8px;

  touch-action: none;
  width: 100%;
  position: relative;

  cursor: pointer;
}

.active-track, .inactive-track {
  height: var(--track-height);
  border-radius: 8px;
}

.active-track {
  background-color: var(--sec-05);
}

.active-track.uninitialized {
  background-color: var(--gray-xl);
}

.inactive-track {
  background-color: var(--gray-xl);
}

.thumb {
  bottom: calc(0px - (var(--thumb-diameter) / 2) - (var(--track-height) / 2));
  height: var(--thumb-diameter);
  width: var(--thumb-diameter);
  background-color: var(--sec-02);
  border: none;
  box-shadow: 0 0 3px #AAA;
}

.thumb.uninitialized {
  background-color: var(--gray-d);
}