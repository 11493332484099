.root {
  margin-top: 20px;
  margin-left: 10px;
}

.row {

}

.item {
  margin-bottom: 30px;
}

.label {
  margin-bottom: 10px;
  font-family: var(--font-semibold);
}

@media (min-width: 600px) {
  .root {
    width: 550px;
  }

  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .item {
    width: 50%;
    margin-bottom: 0;
  }
}