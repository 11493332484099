.item {

  margin-bottom: 15px;
}

@media (min-width: 800px) {
  .item {
    display: flex;
    align-items: center;
  }

  .option {
    
  }
}

.question {
  width: 350px;
  max-width: 350px;
}

.option {
  width: 250px;
  min-width: 250px;

  margin-left: 20px;
}

.remove {
  color: var(--pri-04);
  margin-left: 10px;
  cursor: pointer;
  min-width: 24px;
}