.root {
  padding: 20px var(--gutter-width);
}

.details {
  color: var(--black-l);
}

@media (min-width: 425px) {
  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.title {
  margin-bottom: 10px;
  max-width: 700px;
}

.subtitle {
  display: flex;
  align-items: center;
}

.info {

}

.age {
  font-size: 14px;
}

.breadcrumbs {
  display: flex;
  margin-bottom: 15px;
}

.version {
  margin-right: 10px;
}