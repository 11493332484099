.base {
  border-bottom: 2px solid var(--sec-01);
  padding: 6px 0;
  margin: 2px 0 0 0;
}

.focus {
  border-bottom: 2px solid var(--pri-02);
}

.row {
  margin: 0 0 16px 0;
}

.label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 14px;
}

.label-error {
  color: var(--pri-04);
}

.star {
  color: var(--sec-02);
}

.input {
  composes: input from 'static/css/input.css';
  color: var(--black);
  border-bottom: 2px solid var(--sec-01);
  border-radius: 0;
  font-size: 14px;
  font-family: var(--font-semibold);
  line-height: 28px;
}

.input:focus {
  border-bottom: 2px solid var(--pri-02);
}

.dropdown {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 28px;
  padding: 0;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pri-04);
  font-size: 15px;
  font-family: var(--font-semibold);
  margin: 0 0 20px 0;
}

.actions {
  display: flex;
  justify-content: center;
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
}