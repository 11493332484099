.options {
  margin-bottom: 15px;
}

.checkbox {
  margin-bottom: 25px;
}

.rows {
  margin-bottom: 20px;
}