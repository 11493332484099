.root {
  padding: 15px 0;
}

@media (min-width: 800px) {
  .root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.right {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@media (min-width: 800px) {
  .right {
    margin-top: 0;
    margin-left: 10px;
  }
}

.text {
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.hidden {

}

.show {

}

.switch {
  margin-left: 10px;
}