.footer {
  display: flex;
  justify-content: center;
}

.body {
  max-width: 500px;
  min-height: 175px;
  margin: 0 auto;
}

.dropdowns {
  display: flex;
  align-items: center;

  margin-bottom: 25px;
}

.options {

}

.options-title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 10px;
}

.option {
  margin-bottom: 10px;
}

.question-dropdown {
  width: 300px;
}

.condition-dropdown {
  width: 190px;
  margin-right: 15px;
}

.submit {
  margin-left: 10px;
}