.root {
  display: flex;
  flex-direction: column;
}

.btns {
  display: flex;
  flex-direction: column;
}

.btn {
  margin-bottom: 5px;
  width: 135px;
}