.root {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.avatar {

}

.name {
  font-size: 20px;
  margin-left: 10px;
  font-family: var(--font-semibold);
}

.link:hover .name {
  color: var(--pri-02);
  text-decoration: underline;
}