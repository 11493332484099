.root {
  max-width: 750px;
}

.accordion {
  padding: 0 var(--gutter-width);
}

@media (min-width: 900px) {
  .accordion {
    padding: 0;
  }
}

.wrap {
  margin-top: 10px;
}

.modal {
  max-width: 550px;
}

.btn {
  min-width: 130px;
}