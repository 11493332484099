.root {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 28px;
  color: var(--black-l);
  margin-bottom: 25px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-xl);
}

.question {
  min-height: 400px;
}

.footer {
  margin-top: 50px;
}