@value 800 from 'vars';

.root {
  min-height: 300px;
}

.header {
  color: var(--black);
  text-align: center;
  margin-bottom: 15px;
}


.subheader {
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;
}

.scroll {
  padding: 0 10px;
  height: 55vh;
  overflow-y: auto;
}

@media (min-width: 600px) {
  .scroll {
    height: initial;
    max-height: calc(100vh - 270px);
  }
}

.setup {
  max-width: 520px;
}

@media 800 {
  .setup .scroll {
    padding: 0 40px;
  }
}