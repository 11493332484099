.root {

}

.item {
  display: flex;
  align-content: center;

  margin-bottom: 25px;
}

.ball {
  height: 20px;
  width: 20px;
  border-radius: 50%;

  margin-right: 15px;
}

.text {
  font-size: 18px;
  font-family: var(--font-semibold);
}

.in-progress, .incomplete, .completed {
  composes: item;
}

.in-progress .ball {
  background-color: var(--pri-01);
  border: 1px solid var(--sec-05);
}

.incomplete .ball {
  background-color: var(--pri-01);
  border: 1px solid var(--gray);
}

.completed .ball {
  background-color: var(--sec-05);
  border: 1px solid var(--sec-05);
}

.in-progress .text {
  color: var(--black);
}

.incomplete .text {
  color: var(--gray);
}

.completed .text {
  color: var(--gray-d);
}
