.answer {
  composes: offset-left from 'scenes/surveys/style.css';

  margin-top: 10px;
  width: 85%;
}

.question {
  border-top: 1px solid var(--gray-xl);
  padding: 15px 5px;
}

.question:last-of-type {
  border-bottom: 1px solid var(--gray-xl);
}