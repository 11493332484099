.form {
  width: 300px;
  padding-bottom: 30px;
}

.input {
  composes: input from 'static/css/input.css';
  margin-top: 30px;
}

.btn {
  margin: 35px 0 0;
}

.item {
  color: #555555;
  font-size: 18px;
}

.stripeConnect {
  composes: stripe-connect from 'static/css/stripe.css'
}

.row {

}

.label {
  margin-bottom: 5px;
  font-family: var(--font-semibold);
}