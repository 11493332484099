.root {
  display: flex;
  align-items: center;
  width: 100%;
}

.wave {
  width: 100%;
  position: relative;
}

.duration {
  margin-left: 15px;
}