.linear {
   height: 4px;
   background-color: var(--sec-05);
   transition: all 0.3s ease;
}

.meter {
  position: relative;
  border-radius: 10px;
  background-color: var(--pri-01);
  border: 1px solid var(--sec-01);
  overflow: hidden;
}

.vertical {
  composes: meter;
  width: 15px;
  height: 130px;
  margin: 0 -1px;
}

.horizontal {
  composes: meter;
  width: 130px;
  height: 15px;
  margin: -1px 0;
}

.horizontal .meter-fill {
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  right: 0;
}

.vertical .meter-fill {
  width: calc(100% + 2px);
  bottom: -1px;
  left: -1px;
  right: 0;
}

.meter-fill {
  position: absolute;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.low {
  composes: meter-fill;
  background-color: #c0dffc;
}

.mid {
  composes: meter-fill;
  background-color: #76d1ff;
}

.hi {
  composes: meter-fill;
  background-color: #5c99ff;
}