.header {
  composes: header from './ProjectSummary.css';

  justify-content: flex-start;
  border-bottom: 1px solid var(--sec-01);
}

.h2 {
  composes: h2 from './ProjectSummary.css';

  line-height: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.trunc {
  height: 2em;
  width: 100%;
}

.cloud {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
}

.saved {
  color: var(--pri-03);
  transition: opacity 0.75s ease-out;
  opacity: 0;
}

.saving {
  color: var(--pri-03);
  transition: opacity 0.25s ease-in;
  opacity: 1;
}

.notepad {
  display: flex;
  flex-direction: column;
  padding: 20px;

  min-height: calc(500px - 70px);
  justify-content: space-between;
}

@media (min-width: 900px) {
  .notepad {
    min-height: calc(100% - (70px + 44px));
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 60px;
}

.download {
  width: 110px;
}

.pad {

}

.textarea {
  display: block;
  border-color: transparent;
  font-family: var(--font-reg);
  color: var(--gray-d);
  padding: 0;
  font-size: 16px;
  width: 100%;
  resize: none;
}

.textarea:focus {
  outline: none;
}
