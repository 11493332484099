.root {
  display: inline-block;
  position: relative;
  width: 100%;
}

.input {
  composes: input from 'css/input.css';
  font-size: 16px;
}

.number-input {
  composes: input;
  line-height: 24px;
  padding-bottom: 1px;
}