.root {
  padding: 20px;
}

.author {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.meta {
  margin-left: 10px;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.admin {
  border-radius: 0;
  filter: none;
  cursor: default;
}

.link:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.career {
  font-size: 12px;
  margin-top: 5px;
  color: #6C6C6C;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pad {
  margin-top: 10px;
  line-height: 21px;
}

.delete-btn {
  font-size: 10px;
  text-decoration: underline;
}

.header {
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
}

.actions {
  margin-top: 4px;
  display: flex;
}

.actions > *:not(:first-child) {
  margin-left: 5px;
  transition: none;
}

.reply {
  margin-left: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-left: 1px solid var(--sec-01);
}

.age {
  margin-left: 5px;
  font-size: 12px;
  color: #999999;
}

.expand {
  width: 100%;
  text-align: center;
  color: var(--gray);
  padding: 10px;
  padding-top: 0px;

  display: flex;
  align-items: center;
  margin-left: 20px;
}

.expand:hover {
  color: #0A68EF;
  text-decoration: underline;
}