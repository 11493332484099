@value 800, 1100 from 'vars';

.name {
  font-family: var(--font-semibold);
  font-size: 18px;
  text-align: center;
  height: 45px;
  margin: 0 auto 10px;
}

.description {
  font-size: 16px;
  color: #787878;
  height: 60px;
  text-align: center;
  margin: 0 auto;
}

@media 1100 {
  .description {
    width: 250px;
  }
}

.payment {
  font-family: var(--font-semibold);
  font-size: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 25px 25px 10px;
}

.rate {
  font-size: 18px;
  padding: 0 15px;
}

@media 1100 {
  .rate {
    padding: 0 25px;
  }
}

.check {
  height: 12px;
  width: 12px;
  padding: 2px;
  color: #5C99FF;
  border: 2px solid #5C99FF;
  border-radius: 50%;
  stroke-width: 4;
}

.launch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.btn {
  width: 100%;
}

.transparent {
  background-color: transparent;
}