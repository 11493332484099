.vics {

}

.vics-container {

}

.vics-container-top {
    composes: columns from 'scenes/vics-selection/Tags/style.css';
    min-height: 410px;
}

@media(min-width: 800px){
    .vics-container-top {
        min-height: 260px;
    }
}

.vics-container-bottom {
    composes: columns from 'scenes/vics-selection/Tags/style.css';
}

.vics-subtext {
    composes: vics-subtext from 'scenes/vics-selection/style.css';
}

.warning {
    composes: warning from 'scenes/vics-selection/style.css';
}

.tags {
    composes: tags from 'static/css/tags.css';
}

.selected {
    composes: selected from 'scenes/vics-selection/style.css';
}