.red {
  color: var(--pri-04);
}

.gray {
  color: var(--gray-m);
}

.condition {
  font-style: italic;
  margin-left: 2px;
}