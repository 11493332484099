.root {
  composes: content from '../../style.css';
}

.header {
  border-bottom: 1px solid var(--sec-01);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h1.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 28px;
  line-height: 54px;
}

.section {
  border: 1px solid var(--sec-01);
  border-radius: 10px;
  padding: 14px 20px;
  margin-bottom: 30px;
  min-height: 140px;
}

.section:last-child {
  margin-bottom: 50px;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h2.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 16px;
}