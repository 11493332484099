.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.text {
  color: var(--gray-d);
  font-family: var(--font-reg);
  line-height: 20px;
}

.add  {
  font-size: 15px;
  margin: 20px 0 0 0;
  padding: 0;
  height: 34px;
  width: 160px;
}

.stripe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.stripe-label {
  color: var(--gray-d);
  font-family: var(--font-reg);
  margin: 20px 0 20px 0;
}

.check {
  display: flex;
  align-items: center;
}

.check-label {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 20px 0 20px 0;
}

.edit {
  margin: 0 0 0 40px;
}

.paypal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.paypal-account {
  color: var(--gray-d);
  font-family: var(--font-reg);
  margin: 20px 0 20px 0;
}

.update  {
  font-size: 15px;
  padding: 0;
  height: 34px;
  width: 160px;
}