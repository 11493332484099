.root {
  margin-top: 25px;
}

.row-root {
  margin-bottom: 15px;
}

.row-value {
  margin-bottom: 15px;
}

.options {
  margin-left: 20px;
}

.add {
  composes: add from './Shared.css';
}

.choice {
  composes: choice from './Shared.css';
}

.ordinal {
  composes: ordinal from './Shared.css';
}

.action {
  composes: action from './Shared.css';
}

.header {
  composes: header from './Shared.css';
}

.header-row {
  composes: header-row from './Shared.css';
}

.row {
  composes: row from './Shared.css';
}

.remove {
  composes: remove from './Shared.css';
}

.condition {
  composes: condition from './Shared.css';
}