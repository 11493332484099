.root {
  max-width: 1135px;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.query {
  color: var(--black-l);
  font-size: 18px;
  font-family: var(--font-semibold);
  margin: 30px 0 20px 0;
}