.root {
  padding: 15px 0;
}

.content {
  margin: 0 0 10px 50px;
}

.question-text {
  margin-bottom: 20px;
}

.item {
  margin: 4px 0 0 40px;
}

.notsupported {
  color: var(--gray);
  font-family: var(--font-bold);
  font-size: 15px;
}