.root {
  display: flex;
  align-items: center;
  width: 38px;
  height: 20px;
  border-radius: 25px;
  padding: 3px 7px;
  cursor: pointer;

  position: relative;

  --nob-diameter: 18px;
}

.on {
  justify-content: flex-end;
  background-color: var(--pri-03);
}

.off {
  justify-content: flex-start;
  background-color: var(--gray);
}

.nob {
  height: var(--nob-diameter);
  width: var(--nob-diameter);
  border-radius: 50%;
  background-color: var(--pri-01);

  position: absolute;
  left: calc(var(--nob-diameter) / 2);

  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.on .nob {
  transform: translateX(calc(var(--nob-diameter)));
}