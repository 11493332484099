@value 1100 from 'vars';

.root {
  display: none;
}

@media 1100 {
  .root {
    display: block;
    background-color: var(--pri-01);
  }
}


.wrap {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  background-image: url('static/images/signup/sphere-zags.svg?url');
  background-repeat: no-repeat;
  background-size: 450px 350px;
  background-position: top right;
}

.main {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  max-width: 580px;
}

.header {
}

.headline {
  font-size: 22px;
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
}

.title {
  font-family: var(--font-bold);
  color: var(--black-l);
  text-align: center;
  margin-bottom: 10px;
}

.copy {
  color: var(--black-l);
  margin-bottom: 25px;
  font-size: 14px;
}

.tags {
  display: flex;
}

.tag {
  font-family: var(--font-bold);
  font-size: 12px;
  border-radius: 30px;
  padding: 4px 12px;
  border: 1px solid var(--gray-l);
  margin-right: 15px;
}

.item {
  width: 240px;
  padding: 15px;
  border: 1px solid var(--gray-l);
  border-radius: 19px;
  margin-bottom: 15px;
  background-color: var(--pri-01);
}

.item:nth-child(odd):not(:last-child) {
  margin-right: 15px;
}

.pic {
  text-align: center;
  margin-bottom: 10px;
}

.proof {
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--black-d);
  margin-bottom: 15px;
}

.img {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}

.logos {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.logo {
  height: 25px;
  margin-bottom: 15px;
  margin-right: 25px;
}

.company {

}

.companies {

}