.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  min-height: calc(100vh - var(--navbar-height) - 60px - 95px);
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  margin-left: 15px;
}

@media (max-width: 425px) {
  .card {
    border-bottom: none;
  }
}