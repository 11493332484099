.item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-xl);
  padding: 10px var(--gutter-width);
}

@media (min-width: 900px) {
  .item {
    padding: 10px 0;
  }
}

.item:last-child {
  border-bottom: none;
}

.avatar {
  width: 60px;
  height: 100%;
  margin-right: 10px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 20px 0 10px;
  width: 100%;
}

@media (min-width: 800px) {
  .details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.link {
  composes: link from 'scenes/projects/style.css';
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 18px;
}

.col {

}

.name {
  margin-bottom: 5px;
}

.note {
  display: none;
  color: var(--gray-d);
  font-size: 14px;
  line-height: 35px;
}

@media (min-width: 800px) {
  .note {
    display: block;
  }
}

.info {
  display: flex;
  flex-direction: column;
}

@media (min-width: 800px) {
  .info {
    width: 470px;
    min-height: 100px;
  }
}

.copy {
  display: none;
  position: relative;
  font-family: var(--font-reg);
  color: var(--black-l);
  font-size: 14px;
}

@media (min-width: 800px) {
  .copy {
    display: block;
  }
}

.summary {
  overflow: hidden;
  position: relative;
  line-height: 1.4em;
  max-height: 4.2em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}

.summary:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}

.summary:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: var(--pri-01);
}

.tags {
  display: none;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: wrap;
    overflow: hidden;
    max-height: calc(36px * 2);
    margin: 8px 0;
  }
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 0;
  min-width: 140px;
}

.btns > button,
.btns > a button {
  margin-bottom: 10px;
  width: 100%;
}

@media (min-width: 800px) {
  .btns {
    height: 160px;
    min-width: 160px;
  }
}

.topics {
  overflow: hidden;
  max-height: calc(36px);
  margin-top: 5px;
}