.root {
  margin: 15px auto;
}

.wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50vh;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 20px;
  margin: 50px 0 25px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
}

.btn {
  font-family: var(--font-bold);
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid var(--pri-03);
  margin: 50px auto 0 auto;
  width: 100%;
  height: 50px;
}

@media (min-width: 800px) {
  .root {
    width: 650px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 32px;
    line-height: 45px;
  }

  .copy {
    font-size: 18px;
    line-height: 30px;
  }

  .btn {
    width: 150px;
    margin: 60px 0 0 0;
    height: 60px;
  }
}