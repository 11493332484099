@value 800 from 'vars';

.root {
}

.wrap {
  padding: 60px 0;
}

.main {
  display: flex;
  flex-direction: column;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.list {
  margin-bottom: 40px;
  max-width: 420px;
}

@media 800 {
  .list {
    margin-right: 60px;
  }
}

.title {
  font-size: 30px;
  margin-bottom: 30px;
  color: var(--black);
  font-family: var(--font-semibold);
}

@media 800 {
  .title {
    max-width: 500px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--gray-d);
  margin-bottom: 50px;
}

@media 800 {
  .subtitle {
    max-width: 450px;
  }
}

.topic {
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--black);
  margin-bottom: 30px;
}

.link {
  font-size: 18px;
  color: var(--pri-02);
  margin-bottom: 30px;
}

.link:hover {
  text-decoration: underline;
}