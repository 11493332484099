.root {
  margin: 30px 0 0 0;
  min-height: 200px;
}

.row {
  composes: row from './Shared.css';
}

.desktop-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-title svg {
  margin: 0 8px 0 0;
}