.root {
  padding: 15px 0;
}

.question {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.question-type {

}

@media (min-width: 800px) {
  .question-type {
    display: flex;
  }
}

.question-text {
  margin-bottom: 20px;
}

.dropdown {
  width: 250px;
  padding-top: 5px;
  margin-left: 8px;
}

.buttons {
  margin-top: 20px;
}

.body {
  composes: offset-left from 'scenes/surveys/style.css';
}

.select {
  width: 175px;
  cursor: pointer;
}

.response-type {
  margin-left: 8px;
  margin-top: 10px;
}

@media (min-width: 800px) {
  .response-type {
    margin-left: 25px;
    margin-top: 0;
  }
}

.response-type .title {
  margin-bottom: 10px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
}