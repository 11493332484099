.root {
}

.wrap {

}

.main {
  display: flex;
  align-items: flex-end;
  height: 60px;
}

.header {
  composes: label from './Settings.Branding.Theming.css';
}

.display {
  display: flex;
  justify-content: flex-end;
  position: relative;
  color: var(--sec-01);
}

.btn {
  font-family: var(--font-reg);
  padding: 5px 16px;
  height: 36px;
}

.upload {
  margin-right: 25px;
}

.display .noimage {
  --size: 70px;
  --margin-horizontal: calc(((var(--size) - (var(--size) * 0.75)) / 2) * -1);
  --margin-vertical: calc((var(--size) * 0.15) * -1);
  font-size: var(--size);
  margin: var(--margin-vertical) var(--margin-horizontal);
}

.display .image {
  border-radius: 4px;
  height: 60px;
  width: 60px;
  border: 1px solid var(--gray-l);
  box-sizing: border-box;
}

.remove {
  position: absolute;
  top: -12px;
  right: -18px;
  background-color: var(--blue-xl);
  color: var(--gray-l);
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 0 5px;
  transition: color 150ms ease-in-out,
              box-shadow 150ms ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.remove:hover {
  color: var(--gray);
  box-shadow: 0px 3px 5px 1px  rgba(55,70,70,.15);
}

.landscape {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  color: var(--sec-01);
  width: 125px;
  height: 50px;
  background-color: var(--sec-01);
  border-radius: 4px;
}

.landscape.content {
  background-color: var(--pri-01);
}

.landscape .remove {
  top: -21px;
  right: -28px;
}

.landscape .image {
  height: 60px;
  width: 150px;
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  box-sizing: border-box;
}

.clamp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 3px;
  overflow: hidden;
  background-color: var(--pri-01);
}

.landscape .noimage {
  --size: 70px;
  font-size: var(--size);
  background-color: transparent;
}

.frame:not(:last-child) {
  margin-right: 50px;
}

.cropper {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 0 auto;
}

.previewroot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 175px;
  width: 100%;
}

.picker {
  text-align: left;
  max-width: 175px;
}

.picker input {
  width: 100%;
}

.preview {
  height: 70px;
  width: 70px;
}

.lpicker {
  text-align: center;
  width: 100%;
}

.lpreview {
  height: 40px;
  width: 100px;
  margin: 0 auto;
}

.loriginal {
  max-width: 350px;
  max-height: 140px;
}

.lcropper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
}