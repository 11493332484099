.vics-subtext {
    font-family: var(--font-bold);
    font-size: 18px;
    color: var(--gray-d);
}

.warning {
    color: var(--pri-04);
    font-size: 15px;
    height: 18px;
}

@media (min-width: 425px) {
    .warning {
        margin-left: 10px;
    }
}

.autocomplete {
    max-width: 300px;
}

@media(min-width: 425px){
    .autocomplete {
        max-width: 500px;
    }
}

.selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 425px) {
    .selected {
        justify-content: unset;
    }
}