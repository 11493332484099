.likeBtn {
  color: var(--gray);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  cursor: pointer;
  font-family: var(--font-semibold);
}

.likeBtn:hover {
  background: var(--gray-xl);
  color: var(--black);
}

.likeCounter {
  margin-left: 4px;
  font-size: 13px;
  line-height: 18px;
}

.liked {
  background: rgba(10, 104, 239, 0.16);
  color: #0A68EF;
}

.like-text {
  margin-left: 2px;
}