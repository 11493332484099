.title {
  font-size: 16px;
  font-family: var(--font-semibold);
}

.header {
  display: flex;
  align-items: center;
  max-width: 450px;
  margin-bottom: 10px;
  line-height: 24px;
}

.row {
  margin-bottom: 20px;
}

.field {
  min-height: 100px;
}


.field-child {
  width: 200px;
}

.goal input {
  line-height: 24px;
}

.description {
  resize: vertical;
  min-height: 140px;
}

.inline {
  display: flex;
  flex-direction: column;
}

.inline .field {
  width: 100%;
}

@media (min-width: 600px) {
  .inline {
    flex-direction: row;
  }

  .inline .field {
    width: 195px;
  }

  .inline .field:nth-child(even) {
    margin-left: 35px;
  }
}