.root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.author {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.meta {
  margin-left: 10px;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.admin {
  border-radius: 0;
  filter: none;
  cursor: default;
}

.link:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.career {
  font-size: 12px;
  margin-top: 5px;
  color: #6C6C6C;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.age {
  margin-left: 5px;
  font-size: 12px;
  color: #999999;
}