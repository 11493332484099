.subtitle {
  font-family: var(--font-bold);
  font-size: 16px;
  color: var(--black-l);
  margin-top: 15px;
}

.btn {
  composes: btn from '@/access/website/screens/Auth/style/Login.css';
}

.text {
  composes: text from '@/access/website/screens/Auth/style/Login.css';
}

.link {
  composes: link from '@/access/website/screens/Auth/style/Login.css';
}

.caption {
  color: var(--gray);
  font-family: var(--font-helv);
  font-size: 14px;
  text-align: center;
  line-height: 25px;
}

.grow {
  display: inline-block;
  width: fit-content;
  cursor: pointer;
}

.grow:hover {
  color: var(--pri-02);
}

.underline {
  height: 2px;
  background-color: var(--pri-02);
  width: 0;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 120ms ease-out, transform 120ms ease-out, width 0s linear 180ms;
}

.grow:hover .underline {
  transition: width 180ms ease-out, opacity 120ms ease-in, transform 120ms ease-in;
  opacity: 1;
  transform: scale(1);
  width: 100%;
}

.notify {
  composes: notify from '@/access/website/screens/Auth/style/Login.css';
}

.error {
  composes: error from '@/access/website/screens/Auth/style/Login.css';
}