.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px;

  border-top: 1px solid var(--gray-xl);
}

.root:last-child {
  border-bottom: 1px solid var(--gray-xl);
}

.left {
  display: flex;
  align-items: center;
}

.ordinal {
  margin-right: 30px;
  width: 58px;
}

.dragging {
  background-color: var(--blue-l);
  border: 2px solid var(--pri-02);
  border-radius: 5px;
}

.dragging:last-child {
  border-bottom: 2px solid var(--pri-02);
}

.handle {
  color: var(--gray-d);
}

.dragging .handle {
  color: var(--pri-02);
}
