@value 500, 800 from 'vars';

.filter {
  width: 175px;
  margin-left: 15px;
}

.td {
  height: 55px;
}

.header {
  border-top: none;
}

.content {
  height: calc(100vh - var(--navbar-height) - 60px - 95px); 
}

.body {
  margin-bottom: 45px;
  position: relative;
}

.footer {
  display: flex;
  justify-content: flex-end;

  padding: 10px;

  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);

  border-top: 1px solid var(--sec-01);
}

.loader {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.link {

}

.link:hover, .interaction {
  color: var(--pri-02);
  text-decoration: underline;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
}

.icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.affirm {
  composes: status;
  color: var(--pri-03);
}

.warn {
  composes: status;
  color: var(--pri-04);
}

@media 500 {
  .body {
    height: calc(100vh - var(--navbar-height) - 145px - 95px); /* 60-card header, 40-header, 45-footer, 20-margin-top, 75px below  */
    overflow-y: auto;
    margin-bottom: 0;
  }

  .content {
    height: calc(100vh - var(--navbar-height) - 60px - 95px); 
  }

  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
  }
}

.notes {
  margin-left: 10px;
  cursor: pointer;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100px;
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

@media (max-width: 425px) {
  .card {
    border-bottom: none;
  }
}

.btn {
  padding: 0;
  min-width: 70px;
  height: 35px;
  font-size: 14px;
  /* margin-right: 5px; */
  border: none;
}