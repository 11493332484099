.root {

}

.wrap {
  padding: 50px 20px;
}

.main {
  max-width: 800px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 24px;
  color: var(--black);
  text-align: center;
  margin-bottom: 30px;
}

.text {
  font-size: 20px;
  text-align: center;
  max-width: 650px;
  margin: 0 auto 40px;
}

.img {
  display: flex;
  overflow: hidden;
  padding-top: 19.4%;
  border-radius: 9px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.banner {
  position: relative;
  box-sizing: border-box;
  height: 19.4%;
  border-radius: 9px;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.info {
  font-family: var(--font-semibold);
  font-size: 20px;
  max-width: 570px;
  margin: 0 auto 40px;
  text-align: center;
}

.btn {
  width: 160px;
}