@value 1100, 500 from 'vars';

.root {
  border-bottom: 1px solid var(--gray-xl);
  margin: 0 auto;
}

@media 1100 {
  .root {

  }
}

.wrap {
  padding: 25px;
}

.trunc {
  max-width: calc(100% - 160px);
  min-width: calc(100% - 160px);
}

.content {
  position: relative;
}

@media 1100 {
  .box {
    display: flex;
    width: 125px;
  }
}

.pic {

}

@media 1100 {
  .pic {
    margin-right: 15px;
  }
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  max-height: unset;
  line-height: unset;
}

.matches {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  overflow: hidden;
  max-height: 36px;
}

.match {
  border-radius: 4px;
  margin: 5px 0;
  cursor: unset;
}

.match:not(:last-child) {
  margin-right: 10px;
}

.main {

}

@media 1100 {
  .main {
    display: flex;
  }
}

.info {
  width: 100%;
  max-width: 615px;

}

@media 1100 {
  .info {
    max-width: 60%;
  }
}

@media (min-width: 1200px) {
  .info {
    max-width: 70%;
  }
}

.tags {
  display: none;
}

@media 500 {
  .tags {
    display: block;
  }
}

.checkbox {
  display: none;
}

@media 1100 {
  .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.avatar {
  width: 55px;
  height: 55px;
  margin-bottom: 5px;
}

@media 1100 {
  .avatar {
    width: 65px;
    height: 65px;
  }
}

.name {
  composes: trunc;
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media 1100 {
  .name {
    font-size: 18px;
  }
}

.link:hover {
  text-decoration: underline;
  color: var(--pri-02);
}

.credit {
  composes: trunc;
  margin-bottom: 7.5px;
}

.career {
  width: 100%;
}

.btns {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 160px;
}

.btns > *:not(:last-child) {
  margin-bottom: 7.5px;
}

.topics {
  overflow: hidden;
  max-height: 36px;
}

.copy {
  position: relative;
  font-family: var(--font-reg);
  color: var(--black-l);
  font-size: 15px;
  margin-bottom: 7.5px;
}

@media 1100 {
  .copy {
    display: block;
    font-size: 16px;
  }
}