.tabs {
  display: flex;
  padding-bottom: 15px;
}

.tab {
  margin-right: 50px;
  cursor: pointer;

  font-family: var(--font-bold);
  padding-bottom: 7px;
}

.active {
  cursor: default;
  color: var(--black-l);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--sec-05);
}

.inactive {
  color: var(--gray-m);
}

.inactive:hover {
  color: var(--gray-d);
}