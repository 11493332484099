.tooltip {
  --icon-size: 22px;
  --icon-color: var(--gray-m);
  --icon-hover-color: var(--gray-d);

  background-color: var(--pri-01);
  white-space: pre-wrap;
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  box-shadow: 0 2px 7px 2px rgb(60 64 67 / 20%);
  padding: 10px;
}

.wrap {
  display: flex;
  align-items: center;
}

.colors {
  display: flex;
  align-items: center;
}

.color {
  border-radius: 50%;
  width: var(--icon-size);
  height: var(--icon-size);
  cursor: pointer;
  margin-right: 7px;
}

.color:last-of-type {
  margin-right: 0;
}

.divider {
  height: var(--icon-size);
  width: 1.5px;
  background-color: var(--gray-l);
  margin: 0 10px;
}

.icon {
  cursor: pointer;
  margin-right: 8px;
  width: var(--icon-size);

  stroke: var(--icon-color);
}

.icon:last-of-type {
  margin-right: 0;
}

.icon:hover {
  stroke: var(--icon-hover-color);
}

.comment-icon {
  composes: icon;
}

.trash-icon {
  composes: icon;
}