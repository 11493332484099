.nav {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 auto;
}

@media 800 {
  .nav {
    padding: 0 15px;
    max-width: 1260px;
  }
}