.link {
  overflow: hidden;
}

.link:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.name {
  display: flex;
  align-items: center;

  overflow: hidden;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
}