.popper {
  background-color: var(--pri-01);
  background-clip: padding-box;
  border: 1px solid var(--gray-l);
  border-radius: 2px;
  box-shadow: 1px 1px 2px 1px #eee;
  z-index: 4;
}

.trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
}

.trigger:hover {
  background-color: var(--gray-xl);
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.url {
  width: 220px;
  line-height: 20px;
}

.textbox {
  width: 100%;
  font-family: var(--font-helv);
  font-size: 15px;
  border: none;
  text-overflow: ellipsis;
  line-height: 20px;
}

.textbox::selection {
  background: transparent;
}

.btns {
  display: flex;
  justify-content: space-between;
  width: 310px;
  border-top: 1px solid var(--gray-l);
}

.btn:first-child {
  border-right: 1px solid var(--gray-l);
}

.btn {
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 15px;
  font-family: var(--font-bold);
  color: var(--pri-02);
  background-color: var(--pri-01);
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 15px 0;
  width: 50%;
}

.btn:active {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

@media (min-width: 800px) {
  .btn:hover {
    background-color: var(--pri-02);
    color: var(--pri-01);
  }

  .btn:active {
    opacity: 0.6;
    transition: background-color 120ms cubic-bezier(0.1, -0.6, 0.2, 0) 80ms;
    background-color: var(--pri-02);
    color: var(--pri-01);
  }
}

.reset {
  composes: btn;
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.reset:disabled {
  color: var(--gray);
}

.reset:disabled:hover {
  background-color: var(--pri-01);
  cursor: not-allowed;
}

.share {
  font-family: var(--font-bold);
  line-height: 20px;
}

.share:after {
  display: block;
  content: 'Share';
}

.copied {
  font-family: var(--font-semibold);
  color: var(--pri-02);
  line-height: 20px;
  font-size: 14px;
  animation-name: fade-in;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
}

.copied:after {
  display: block;
  content: 'Copied';
}

.copy {
  composes: btn;
}

.copyicon {
  height: 15px;
  width: 15px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hide-mobile {
  display: none;
}

@media (min-width: 800px) {
  .hide-mobile {
    display: inline;
  }
}