.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.2s ease-in;
}

.root :not(:first-child) {
  margin-left: 10px;
}

.plus {
  color: var(--pri-02);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
}