.root {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  cursor: pointer;
  fill: #9B9B9B;
}

.icon.disabled {
  fill: var(--gray-l);
  cursor: not-allowed;
}

.root:hover {
  background-color: var(--gray-xl);
}

.root:hover .icon {
  fill: var(--gray-d);
}