.root {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 100%;
}

.root > div {
  display: flex;
  margin: 0 4px 0 0;
}

.root > div:last-child {
  margin: 0;
}