@value 1100 from 'vars';

.root {
  display: none;
}

@media 1100 {
  .root {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.list {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 70px;
  height: 100%;
}

.item {
  font-family: var(--font-semibold);
  color: #4A4A4A;
}

.item:hover {
  color: var(--pri-02);
}

.item:last-child {
  margin-right: 0px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.anchor {
  position: relative;
}

.anchor:hover .menu.hide,
.menu.hide:hover {
  display: block;
}

.pad {
  width: 150px;
  padding: 15px 25px 0px;
  margin-top: 16px;
  background-color: var(--pri-01);
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.09);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.menu {
  position: absolute;
  top: 28px;
  left: -10px;
}

.link {
  width: 100%;
  height: 40px;
  color: var(--gray-d);
}

.btn {

}

.btn:hover .link {
  color: var(--black-d);
}

.hide {
  display: none;
}

.show {
  display: block;
}