@value 800 from 'vars';

.wrap {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media 800 {
  .wrap {
    display: block;
    margin: 90px 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media 800 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }
}

.headline {
  margin-bottom: 50px;
  max-width: 820px;
  font-family: var(--font-semibold);
  font-size: 30px;
}

@media 800 {
  .headline {
    margin-bottom: 90px;
  }
}

.benefit {
  margin-bottom: 50px;
  text-align: center;

  width: 225px;
}

@media 800 {
  .benefit {
    margin-bottom: 0;
  }
}

.caption {
  composes: caption from '@/access/website/screens/Main/style.css';

  margin: 20px 0 30px 0;
}

@media (min-width: 1100px) {
  .caption {
    margin: 60px 0 30px 0;
  }
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 125px;
  width: 125px;
  margin: 0 auto;

  border-radius: 50%;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.img {
  height: 80px;
  width: 80px;
}