.root {
  margin: auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 10px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
  margin: 0 0 40px 0;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.step {
  margin: 0 0 30px 0;
}

.step-label {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 16px;
  margin: 0 0 12px 0;
}

.step-content {
  --step-border-radius: 4px;

  border: 1px solid #e0e0e0;
  border-radius: var(--step-border-radius);
}

.country {
  padding: 20px 30px;
  min-height: 30px;
  max-width: 300px;
}

.country-dropdown {
  font-size: 14px;
  font-family: var(--font-reg);
}

.country-locked {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 20px 30px;
  min-height: 30px;
}

.divider:not(:last-child) {
  border-top: 1px solid #e0e0e0;
}

.payment-option {
  display: flex;
  align-items: center;
  background-color: var(--gray-xl);
  color: var(--gray-m);
  cursor: pointer;
  font-family: var(--font-bold);
  font-size: 16px;
  padding: 20px 30px;
  /* transition: background-color 75ms ease-in-out,
              color 75ms ease-in; */
}

.payment-option:first-of-type {
  border-top-left-radius: var(--step-border-radius);
  border-top-right-radius: var(--step-border-radius);
}

.payment-option:last-of-type {
  border-bottom-left-radius: var(--step-border-radius);
  border-bottom-right-radius: var(--step-border-radius);
}

.payment-option-selected {
  background-color: var(--pri-01);
  color: var(--black-l);
}

.payment-option-label {
  font-size: 16px;
  margin: 0 0 0 10px;
}

.stripe {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.stripe-copy {
  margin: 0 0 20px 0;
}

.address-entry {
  padding: 30px 30px 40px 30px;
}

.address-entry-label {
  font-size: 14px;
  line-height: 30px;
}

.address-entry-field:not(:last-of-type) {
  margin: 0 0 20px 0;
}

.address-entry-field input {
  font-size: 14px;
}

.stripe-completed {
  font-size: 16px;
  font-family: var(--font-semibold);
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--pri-03);
  margin: 0 0 10px 0;
}

.check-icon {
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s ease;
  color: var(--pri-03);
  border: 2px solid var(--pri-03);
}

.star {
  color: var(--sec-02);
}

.paypal-entry {
  padding: 30px 30px 40px 30px;
}

.paypal-label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 30px;
}

.paypal {
  composes: input from 'static/css/input.css';
  color: var(--black);
  border-bottom: 2px solid var(--sec-01);
  border-radius: 0;
  font-size: 14px;
  font-family: var(--font-semibold);
  line-height: 28px;
}

.paypal:focus {
  border-bottom: 2px solid var(--pri-02);
}

@media (min-width: 800px) {
  .root {
    max-width: 700px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
  }

  .copy {
    font-size: 20px;
    line-height: 24px;
  }

  .step-label {
    font-size: 18px;
    margin: 0 0 12px 0;
  }

  .country-dropdown {
    font-size: 16px;
  }

  .payment-option {
    font-size: 18px;
  }

  .address-entry-label {
    font-size: 16px;
    line-height: 30px;
  }
  
  .address-entry-field input {
    font-size: 16px;
  }

  .navigation {
    margin: 30px 0 20px 0;
  }
}