.root {
  padding: 15px 5px;
}

.form {
  margin-bottom: 10px;
}

.content {
  composes: offset-left from 'scenes/surveys/style.css';
}

.question-text {
  font-family: var(--font-semibold);
  color: var(--black-l);
  font-size: 15px;
  margin-bottom: 25px;
}