.row, .header {

}

.header {
  display: none;
}

@media (min-width: 800px) {
  .row, .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
}

.action {
  width: 150px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin-top: 20px;
}

@media (min-width: 800px) {
  .action {
    margin-top: 0;
  }
}

.ordinal {
  width: 80px;
  margin-top: 15px;
}

@media (min-width: 800px) {
  .ordinal {
    margin-left: 30px;
    margin-top: 0;
  }
}