.root {

}

.row {
  margin-bottom: 15px;
}

.row-value {
  margin-bottom: 10px;
}

.options {
  margin-left: 40px;
}