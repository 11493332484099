.root {
}

.content {
  color: var(--gray);
  font-size: 15px;
}

.state {
  font-family: var(--font-bold);
}

.action {
  font-family: var(--font-bold);
}

.entity {
  font-family: var(--font-bold);
}