.root {

}

.wrap {
  padding: 25px 0;
  text-align: center;
}

@media (min-width: 800px) {
  .wrap {
    padding: 50px 0;
  }
}

@media (min-width: 1100px) {
  .wrap {
    padding: 80px 0;
  }
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';

  margin-bottom: 50px;
  text-align: center;
}

.endorsements {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.img {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .img {
    max-width: unset;
    width: 750px;
  }
}

@media (min-width: 1100px) {
  .img {
    width: 1100px;
  }
}