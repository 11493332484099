.root {

}

.filter-btn {
  border: 1px solid var(--gray-xl);
  border-radius: 8px;
  padding: 6px 8px;
  color: var(--gray-d);
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  margin-right: 10px;
  font-size: 14px;
}

.filter-btn > span {
  margin-left: 5px;
}

.filter-btn:hover,.filter-btn.open {
  background: var(--gray-xl);
  border-color: var(--gray-xl);
}

.filter-btn.active {
  background: var(--blue-l);
  border-color: var(--blue-l);
}

.filter-btn.disabled {
  color: var(--gray-l);
  cursor: default;
}

.filter-icon-container {
  position: relative;
  height: 20px;
}

.filter-active-circle {
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 8px;
  background-color: var(--pri-02);
  right: -1px;
  top: -1px;
  border: 2px solid var(--blue-l);
  display: none;
}

.filter-btn.active .filter-active-circle {
  display: inline;
}

.base-popover {
  border-radius: 5px;
  border: 1px solid var(--gray-l);
  box-shadow: var(--box-shadow);
  background: white;
  z-index: var(--z-index-popper);
  box-sizing: border-box;
  overflow: hidden;
}

.hoverable {
  background: var(--blue-xl);
  color: var(--pri-02);
}

.filter-popover {
  composes: base-popover;
  max-width: 550px;
  min-width: 180px;
}

.filter-row {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid var(--gray-xl);
  width: 520px;
  align-items: center;
}

.filter-row:last-child {
  border-bottom: none;
}

.filter-row > * {
  margin-left: 5px;
  margin-right: 5px;
}

.filter-row-input {
  flex-grow: 1;
  overflow: hidden;
}

.filter-row-input > .selector-button {
  max-width: none;
  box-sizing: border-box;
  width: calc(100% - 6px);
}

.selector-button {
  padding: 5px 10px;
  background: white;
  box-shadow: var(--shadow);
  cursor: pointer;
  border-radius: 8px;
  width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  margin: 3px;
}

.question-selector-button {
  width: 38px;
  text-overflow: unset;
}

.option-selector-button {
  width: 120px;
}

.selector-popover {
  composes: base-popover;
  max-width: none;
  max-height: 400px;
  overflow-y: auto;
}

.multichoice-popover {
  padding: 10px;
}

.multichoice-toggle-all {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 12px;
}

.multichoice-toggle-all > *:hover {
  background: var(--blue-l);
}

.checkbox-group-container {
  max-height: 200px;
  overflow-y: auto;
}

.checkbox-group label > span {
  font-size: 14px !important;
}

.selector-popover-row {
  cursor: pointer;
  padding: 5px 10px;
}

.selector-popover-row:hover {
  background: var(--blue-xl);
  color: var(--pri-02);
}

.fancy-text-input {
  border: none;
  box-shadow: var(--shadow);
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 16px;
  font-family: var(--font-reg);
  height: 21px;
  margin-left: 8px;
  margin-right: 8px;
}

.delete-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--gray-d);
}

.delete-filter > svg {
  border-radius: 18px;
  width: 24px;
  padding: 6px;
}

.delete-filter > svg:hover {
  background: var(--gray-xl);
}

.add-filter {
  cursor: pointer;
  font-family: var(--font-semibold);
  width: auto;
}

.add-filter:hover {
  background: var(--blue-xl);
  color: var(--pri-02);
}


.hide-mobile {
  display: none;
}

@media (min-width: 800px) {
  .hide-mobile {
    display: inline;
  }
}