.participants {
  padding: 12px 0;
}

.participant {
  margin-bottom: 8px;
}

.member {
  color: var(--gray);
  margin-left: 5px;
  font-size: 14px;
}
