.row, .header {

}

.header {
  display: none;
}

@media (min-width: 800px) {
  .row, .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
}

.option {
  margin-top: 20px;
}

@media (min-width: 800px) {
  .option {
    width: 240px;
    margin: 0 20px;
  }
}

.action {
  width: 150px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .action {
    margin-top: 0;
  }
}

.ordinal {
  margin-top: 15px;
  width: 80px;
}

@media (min-width: 800px) {
  .ordinal {
    margin-left: 10px;
    margin-top: 0;
  }
}

.condition {
  margin-top: 15px;
  width: 115px;
}

@media (min-width: 800px) {
  .condition {
    margin-top: 0;
  }
}

.mobile-title {
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .mobile-title {
    display: none;
  }
}