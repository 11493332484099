.card {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

@media (min-width: 425px) {
  .card {
    margin-top: 20px;
    margin-bottom: 100px;
    min-height: calc(100vh - var(--navbar-height) - 120px);
    height: calc(100vh - var(--navbar-height) - 120px);
  }
}

@media (max-width: 425px) {
  .card {
    border-bottom: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  margin-left: 15px;
}

.body {
  margin-bottom: 45px;
}

.td {
  height: 55px;
}

.footer {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);

  border-top: 1px solid var(--sec-01);
}

.rows {
  display: none;
}

@media (min-width: 425px) {
  .body {
    height: calc(100vh - var(--navbar-height) - 155px - 120px); /* 60-card header, 40-header, 45-footer, 120px margin  */
    overflow-y: auto;
    margin-bottom: 0;
  }

  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
  }

  .rows {
    display: flex;
  }
}

.dragging {

}

.dragging .body {
  background-color: var(--blue-xl);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%230A68EFFF' stroke-width='4' stroke-dasharray='6%2c 10' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 3px;
}