.root {
  max-width: 900px;
  margin: 0 auto;
}

.wrap {
  padding: 25px 15px;
}

.back {
  margin-bottom: 15px;
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 30px;
}

.btns {
  text-align: center;
  margin-top: 25px;
}

.save {
  width: 200px;
}