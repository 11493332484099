.step {
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--gray);
}

.done {
  color: var(--gray-d);
}

.active {
  color: var(--black);
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 45px;
}

.bullet {
  height: 20px;
  width: 20px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: var(--pri-01);
  border: 1px solid var(--gray);
}

.outline {
  border: 1px solid var(--sec-05);
}

.fill {
  background-color: var(--sec-05);
  border: 1px solid var(--sec-05);
}