.root {
  margin: 15px auto;
}

.wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50vh;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 20px;
  margin: 50px 0 25px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

@media (min-width: 800px) {
  .root {
    width: 700px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 32px;
    line-height: 45px;
  }

  .copy {
    font-size: 18px;
    line-height: 30px;
  }
}