.root {
  max-width: 950px;
  margin: 0 auto;
}

.wrap {
  padding: 25px 15px;
}

.details {
  margin-bottom: 5px;
}

@media (min-width: 800px) {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .details {
    margin-bottom: 0;
  }
}


.name {
  margin-bottom: 10px;
}

.age {
  color: var(--gray-d);
  font-size: 14px;
}

.progress {
  width: 250px;
  margin: 20px 0 30px 0;
}

.title {
  font-family: var(--font-semibold);
  margin-bottom: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.subtitle {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 15px;
  margin-left: 5px;
}

.row {
  margin-bottom: 25px;
}

.top-row {
  composes: row;
}

.respondents {
  margin-bottom: 25px;
}

.respondents, .terminations {
  width: 100%;
}

@media (min-width: 800px) {
  .top-row {
    display: flex;
  }

  .respondents {
    margin-bottom: 0;
  }

  .respondents, .terminations {
    width: 50%;
  }
}

.basic {
  composes: row;
}

.advanced {
  composes: row;
}

.download {
  color: var(--pri-02);
  cursor: pointer;
}

.download:hover {
  text-decoration: underline;
}

.grant-access {
  margin-bottom: 20px;
  width: 150px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}