@value 800, 1100 from 'vars';

.root {
  width: 100%;
  background-color: var(--pri-01);
  border-bottom: 1px solid var(--gray-xl);
  z-index: var(--z-index-navbar);
  touch-action: manipulation;

  position: fixed;
  top: 0;

  box-shadow: 0 2px 4px 0 rgba(36,50,66,.075);
}

.wrap {
  background-color: var(--pri-01);
  height: var(--navbar-height);
  padding: 0 10px;
  max-width: 1280px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    padding: 0 25px;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.pad {
  /* height: var(--navbar-height); */
  height: var(--top-offset);
  margin-right: 50px;
}

.link {
  font-family: var(--font-semibold);
  color: #4A4A4A;
  margin-right: 70px;
}

.link:hover {
  color: var(--pri-02);
}

.link:last-child {
  margin-right: 0px;
}

.links {
  display: none;
}

@media 1100 {
  .links {
    display: flex;
  }
}

.logo {
  height: 30px;
  width: 155px;
}


.nav {
  display: flex;
  align-items: center;
}

.actions {
  display: none;
}

@media 1100 {
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 230px;
  }
}

.btn {
  padding: 0px 10px;
  height: 40px;
}

.signup {
  composes: btn;
  width: 120px;
}

.demo {
  composes: btn;
  width: 160px;
}

.login {
  font-family: var(--font-semibold);
  color: #4A4A4A;
  margin-right: 25px;
  min-width: unset;
  border: none;
}

.login:hover {
  color: var(--pri-02);
  border: none;
}

.theme.logo,
.theme .logo {
  height: 40px;
  width: 40px;
}

.theme.logo.landscape,
.theme .landscape {
  height: 40px;
  width: 100px;
}