@value 800 from 'vars';

.root {
  width: 100%
}

@media 800 {
  .root {
    display: flex;
  }
}

.wrap {
  width: 100%;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.item {
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
}

@media 800 {
  .item {
    width: 33%;
  }
}

.item:nth-child(even) {
  width: calc(50% - 20px);
  margin-left: 20px;
}

@media 800 {
  .item:nth-child(even) {
    width: 33%;
    margin-left: 0;
  }
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  height: 40px;
}

.items {
  display: flex;
  flex-direction: column;
}

.link {
  display: inline-block;
  height: 40px;
  color: var(--black);
}

.link:hover {
  text-decoration: underline;
}