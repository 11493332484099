.root {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.local {
  transform: rotateY(180deg);
}

/* .root {
  width: 100%;
  height: 100%;
} */