.u {
  text-decoration: underline;
}

.b {
  font-family: var(--font-bold);
}

.i {
  font-style:  italic;
}

.p {
  margin-bottom: 20px;
}

.in {
  margin-left: 20px;
}

.ul {
  padding-inline-start: 15px;
}

.header {
  border-bottom: 2px solid var(--gray-xl);
  margin-bottom: 20px;
}