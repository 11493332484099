.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  width: 380px;
}

.subheader {
  font-family: var(--font-reg);
  font-size: 15px;
  line-height: 34px;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
}

.sync {
  width: 150px;
}

.image {
  width: 210px;
  height: 74px;
}