.icon-camera,
.icon-camera-off {
  composes: icon from './Shared.css';
  border: 1px solid var(--gray-l);
}

.icon-camera svg,
.icon-camera-off svg {
  height: 32px;
  width: 32px;
}

.icon-camera-off {
  color: var(--pri-01);
  background-color: var(--pri-04);
  border-color: var(--pri-04);
}