@value 500, 800, 1200 from 'vars';

.root {
  --card-header-height: 60px;
  --header-height: 65px;

  display: flex;
  flex-direction: column;
  height: calc(100% - var(--header-height));
}

@media 800 {
  margin: 0 auto;
}

@media 1200 {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px;
  height: var(--card-header-height);
  box-sizing: border-box;
}

.title {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.name {
  font-family: var(--font-semibold);
  color: var(--gray-d);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  flex-shrink: 0;
  margin-right: 10px;
}

.body {
  height: calc(100% - var(--card-header-height) - 1px);
  overflow-y: auto;
}

.download {
  cursor: pointer;
  color: var(--gray);
}

.download:hover {
  color: var(--pri-02);
}