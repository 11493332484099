@value 500, 600, 800, 1100, 1200 from 'vars';

.root {
  height: 100%;
}

.wrap {

}

@media 800 {
  .wrap {
    /* padding-top: 20px; */
  }
}

.main {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media 1200 {
  .main {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}