.root {
  width: 100%;
  background-color: var(--gray-xl);
}

.wrap {
  width: 100%;
  max-width: var(--navbar-width);
  margin: 0 auto;
}

.main {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.content {
  display: flex;
  align-items: center;
  color: var(--gray-d);
}

.span {
  font-size: 15px;
  margin-right: 10px;
}

.img {
  width: 100px;
}