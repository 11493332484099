.main {
  composes: wrap from '@/access/website/screens/Main/style.css';
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';

  margin: 40px auto 25px auto;
  max-width: 350px;
  text-align: center;
}

@media (min-width: 800px) {
  .headline {
    max-width: unset;
    margin: 40px 0 15px 0;
  }
}

.bg {
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: 400px) {
  .bg {
    max-width: 400px;
    left: calc(50% - 200px);
  }
}

@media (min-width: 815px) {
  .bg {
    width: 680px;
    left: calc(50% - 340px);
    max-width: unset;
  }
}

@media (min-width: 1100px) {
  .bg {
    width: 1150px;
    left: calc(50% - 575px);
  }
}

.endorsements {
  position: relative;
  z-index: 2;
}

@media (min-width: 815px) {
  .endorsements {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media (min-width: 1100px) {
  .endorsements {
    max-width: 1000px;
    margin:0 auto;
  }
}
.card-container {
  padding-top: 40px;
  position: relative;

  margin: 0 auto;
}

@media (min-width: 815px) {
  .card-container {
    max-width: 815px;
    padding-top: 75px;
  }
}

@media (min-width: 1100px) {
  .card-container {
    max-width: unset;
    padding-top: 60px;
  }
}

.card-root {
  max-width: 100%;
  width: 325px;
  margin: 0 auto 50px auto;
}

.card-root:nth-of-type(1n+5){
  display: none;
}

@media (min-width: 815px) {
  .card-root {
    width: 300px;
    margin: 0 0 30px 0;
  }

  .card-root:nth-of-type(1n+5){
    display: initial;
  }
}

@media (min-width: 1100px) {
  .card-root {
    font-size: 17px;
  }
}

.card {
  padding: 25px;

  border-radius: 5px;
  box-shadow: 1px 1px 7px 0px rgba(0,0,0,0.1);

  background-color: var(--pri-01);
}

.quote {
  margin-bottom: 20px;
}

.name {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.endorser {
  display: flex;
  align-items: center;
}

.img {
  margin-right: 15px;
  border-radius: 50%;
  width: 60px;
}

.avatar {

}

.company, .title, .role {
  font-size: 14px;
}

@media (min-width: 815px) {
  .card-one {
    width: 340px;
    margin-right: 40px;
  }

  .card-one .quote {
    width: 280px;
  }

  .card-two {
    width: 360px;
    margin-top: 5px;
  }

  .card-two .quote {
    width: 290px;
  }

  .card-three {
    width: 320px;
    margin-right: 40px;
  }

  .card-three .quote {

  }

  .card-four {
    width: 350px;
    margin-top: 25px;
  }

  .card-four .quote {
    width: 280px;
  }

  .card-five {
    width: 340px;
    margin-right: 40px;
  }

  .card-five .quote {

  }

  .card-six {
    width: 350px;
    margin-top: 25px;
  }
}

@media (min-width: 1100px) {
  .card-one {
    width: 440px;
    margin-right: 40px;
    margin-top: 20px;
    margin-left: 40px;
  }

  .card-one .quote {
    width: 375px;
  }

  .card-two {
    width: 450px;
    margin-left: 0;
  }

  .card-two .quote {
    width: 385px;
  }

  .card-three {
    width: 410px;
    margin-right: 50px;
    margin-top: 0;
  }

  .card-three .quote {
    width: 320px;
  }

  .card-four {
    width: 430px;
    margin-top: 12px;
    margin-left: 0;
  }

  .card-four .quote {
    width: 350px;
  }

  .card-five {
    width: 420px;
    margin-top: 0;
    margin-right: 60px;
  }

  .card-six {
    width: 415px;
    margin-top: 20px;
    margin-left: 30px;
  }
}