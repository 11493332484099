.root {
  --gutter-width: 15px;
  height: calc(100vh - var(--navbar-height));
  max-width: 950px;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}