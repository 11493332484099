.root {
  /* height: 55px; */
  border-bottom: 1px solid var(--gray-l);
  z-index: var(--z-index-navbar);
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 15px;
}

.left {
  display: flex;
  align-items: center;
}

.right {

}

.close {
  margin-right: 10px;
  color: var(--gray-d);
}

.body {

}

.title {
  font-size: 14px;
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.subtitle {
  font-size: 11px;
}

.btn {
  background-color: var(--pri-03);
  border: 1px solid var(--pri-03);
  height: 35px;
  padding: 5px;
  font-size: 14px;
}