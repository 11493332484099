.item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-xl);
  padding: 10px var(--gutter-width);
}

@media (min-width: 900px) {
  .item {
    padding: 10px 0 10px 10px;
  }
}

.item:last-child {
  border-bottom: none;
  margin-bottom: 20px;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 800px) {
  .details {
  }
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (min-width: 800px) {
  .user {
    flex-direction: row;
    align-items: center;
    height: 90px;
  }
}

.title {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.notes {
  display: none;
  width: 300px;
  padding: 0 15px;
}

@media (min-width: 800px) {
  .notes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.text {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 14px;
  margin-bottom: 8px;
}

.text:last-child {
  margin-bottom: 0;
}


.avatar {
  width: 60px;
}

.header {
  color: var(--gray-d);
  font-family: var(--font-bold);
  margin-bottom: 15px;
}

@media (min-width: 800px) {
  .header {
    margin-bottom: auto;
  }
}

.day {
  margin-bottom: 10px;
}

.time {
  font-size: 15px;
}

@media (min-width: 800px) {
  .time {
    font-size: 16px;
  }
}

.fullname {
  composes: fullname from 'scenes/projects/style.css';
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 0;
  min-width: 140px;
}

@media (min-width: 800px) {
  .btns {
    min-width: 160px;
  }
}

.btns > button,
.btns > a button {
  margin-bottom: 10px;
  width: 100%;
}

.link {
  composes: link from 'scenes/projects/style.css';
}

.recordingStatus {
  font-size: 14px;
}

.label {
  margin-right: 5px;
}

.call-request-text {
  font-family: var(--font-reg);
}