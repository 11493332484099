.root {
  padding: 0 10px 20px;
}

.sticky {
  background-color: #fff;
  position: sticky;
  top: calc(var(--navbar-height) + 53px);
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  line-height: 22px;
}

.label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 16px;
}

.player {
  border-bottom: 1px solid var(--gray-xl);
  padding-bottom: 15px;
}

.audio-player {
  height: 80px;
  border-bottom: 1px solid var(--gray-xl);
  padding-bottom: 15px;
}

.player video {
  width: 50%;
  max-height: 50%;
  outline: none;
}

.loader {
  position: relative;
  height: 250px;
}