@value 800 from 'vars';
@value 1000 from 'vars';
@value 1100 from 'vars';
@value 1200 from 'vars';

.root {
  padding-bottom: 40px;
  padding-top: 20px;
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media 1000 {
  .wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.main {

}

@media 1000 {
  .main {
    display: flex;
    justify-content: space-between;
  }
}

@media 800 {
  .details.info {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.details {
  max-width: 490px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
}

.profiles {
  display: none;
  width: 400px;
}

@media 1000 {
  .profiles {
    display: flex;
    justify-content: flex-end;
  }
}

@media 1200 {
  .profiles {
    display: block;
    width: 100%;
    max-width: 640px;
  }
}

.frame {
  width: 400px;
  height: 373px;
  background-color: var(--gray-xl);
  padding: 15px;
  border-radius: 7px;
}

@media 1200 {
  .frame {
    width: 100%;
    height: 425px;
  }
}

.graphic {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 400px;
  height: 400px;
}

@media 1000 {
  .graphic {
    width: 100%;
    height: 425px;
    background-image: url('static/images/invite/profiles-tablet.png');
  }
}

@media 1200 {
  .graphic {
    background-image: url('static/images/invite/profiles-desktop.png');
  }
}

.btns {
  text-align: center;
}

@media 800 {
  .btns {
    display: flex;
    text-align: left;
  }
}

.btn {
  width: 100%;
  max-width: 400px;
}

@media 1000 {
  .btn {
    font-size: 18px;
    width: 220px;
    margin: 0;
  }
}

.text {
  font-size: 20px;
  color: var(--gray-d);
}

.row {
  padding-bottom: 20px;
}

.btn:first-child {
  margin-bottom: 20px;
}

@media 800 {
  .btn:first-child {
    margin: 0 15px 0 0;
  }
}

.title {
  font-family: var(--font-semibold);
  font-size: 30px;
  color: #1d1d1d;
}

h2.text {
  font-family: var(--font-semibold);
}

.group {
  margin-bottom: 20px;
}