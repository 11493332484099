.icon {
  composes: icon from './Bar.Right.css';
  height: 40px;
  width: 40px;
}

.active {
  composes: active from './Bar.Right.css';
}

.icon svg {
  height: 30px;
  width: 30px;
}

.participants {
}

.header {
  color: var(--black);
  font-size: 20px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}

.sub-header {
  color: var(--black);
  font-size: 14px;
  font-family: var(--font-semibold);
  margin: 0 0 8px;
}


.row {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.name {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 100px;
}

.avatar {
  margin: 0 10px 0 0;
}

.actions {
  display: flex;
  align-items: center;
  max-height: 34px;
}

.action-button {
  font-size: 14px;
  padding: 4px 0;
  min-width: 65px;
  margin: 0 4px 4px 0;
}

.mic-off {
  display: flex;
  color: var(--pri-04);
  margin-right: 4px;
}

.mic-on {
  display: flex;
  color: var(--gray-d);
  cursor: pointer;
  margin-right: 4px;
}

.mic-on:hover {
  color: var(--pri-04);
}

.mic-placeholder {
  width: 24px;
  margin-right: 4px;
}

.phone-on {
  display: flex;
  color: var(--gray-d);
  margin-right: 4px;
}

.visibility-off,
.visibility-on {
  display: flex;
  color: var(--gray-d);
  margin-right: 6px;
}

.popper {
  font-size: 14px;
  z-index: var(--z-index-popper);
}

.popper-placeholder {
  width: 35px;
}