@value 500, 800, 1200 from 'vars';

.root {
  height: 100%;
}

.wrap {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

@media 800 {
  .wrap {
    max-width: 800px;
  }
}

@media 1200 {
  .wrap {
    max-width: 900px;
  }
}

.header {
  padding: 15px 0;
  display: flex;
}