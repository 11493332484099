.btn {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  cursor: pointer;
  align-items: center;
  padding: 2px 4px;
  height: 30px;
  min-width: initial;
  width: 100%;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
  background-color: var(--pri-01);
}

.btn:hover {
  background-color: var(--gray-xl);
}

.label {
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 15px;
  margin: 0 10px;
}

.input {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  min-width: 150px;
}

.gutters {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}