.spacer {
  height: 55px;
}

.question {
  padding: 15px 0;
  border-top: 1px solid var(--gray-xl);
}

.question:last-of-type {
  border-bottom: 1px solid var(--gray-xl);
}

.placeholder {
  color: var(--gray-m);
  margin: 20px 0;
}