.banner {
  background-color: var(--blue-l);
  border: 1px solid var(--pri-02);
  padding: 12px 25px;
  margin: 0 var(--gutter-width) 20px var(--gutter-width);
  cursor: pointer;
  border-radius: 5px;
}

@media (min-width: 500px) {
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 900px) {
  .banner {
    margin: 0 0 20px 0;
  }
}

.banner .text {
  color: var(--pri-02);
  font-family: var(--font-semibold);
}

.banner .link {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  cursor: pointer;
  margin-top: 15px;
}

@media (min-width: 500px) {
  .banner .link {
    margin: 0;
  }
}