@value 1100 from 'vars';
@value 900 from 'vars';
@value 800 from 'vars';

.section {
  padding-left: 0;
  padding-right: 0;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media 800 {
  .root {
    border-bottom: 1px solid var(--gray-xl);
    padding-left: 35px;
    padding-right: 35px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  height: calc(100vh - var(--navbar-height));
  min-height: 640px;
  max-width: 1280px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    background-position: top 120px right 10px;
    background-size: auto 300px;
    background-image: url('static/images/website/colorful-dots-tablet.png');
  }
}

@media 900 {
  .wrap {
    background-position: top 120px right 30px;
    background-size: auto 350px;
  }
}

@media 1100 {
  .wrap {
    background-position: right 40px center;
    background-image: url('static/images/website/colorful-dots.png');
    background-size: auto 550px;
  }
}

.main {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

@media 800 {
  .main {
    margin-top: 80px;
    min-height: 500px;
    padding-bottom: 0;
  }
}

@media 1100 {
  .main {
    margin-top: 320px;
    margin-bottom: 0;
  }
}

.title {
  composes: title from '@/access/website/screens/Main/style.css';
  max-width: 350px;
  width: 100%;
  text-align: center;
  margin: 0 auto 60px;
}

@media 800 {
  .title {
    text-align: left;
    margin: 0 0 30px;
    max-width: 400px;
  }
}

@media 1100 {
  .title {
    max-width: 500px;
  }
}


.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
  max-width: 425px;
  margin: 0 auto 80px;
  text-align: center;
}

@media 800 {
  .copy {
    margin: 0 0 40px;
    text-align: unset;
  }
}

.action {
  width: 100%;
  margin: 0 auto 80px;
  text-align: center;
}

@media 800 {
  .action {
    display: flex;
    margin: 0 auto 40px;
  }
}

.btn {
  width: 100%;
  max-width: 400px;
}

.btn:first-child {
  margin-bottom: 20px;
}

@media 800 {
  .btn {
    font-size: 18px;
    width: 210px;
    margin: 0;
  }

  .btn:first-child {
    margin: 0 15px 0 0;
  }
}

.dots {
  display: none;
  position: absolute;
  right: 35px;
  top: 25px;
}

.dots-tablet {
  display: none;
  position: absolute;
  right: 35px;
  top: 0px;
}

@media 800 {
  .dots-tablet {
    display: block;
    width: 300px;
    top: 125px;
  }
}

@media 800 {
  .dots-tablet {
    width: 400px;
  }
}

@media 1100 {
  .dots {
    display: block;
    width: 550px;
  }

  .dots-tablet {
    display: none;
  }
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

@media 800 {
  .link {
    justify-content: flex-start;
  }
}