.root {
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  padding: 15px;
}

.wrap {
  padding-top: 30px;
}

.header {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 40px;
}

.body {
  font-family: var(--font-reg);
  color: var(--black-l);
  font-size: 18px;
}

.body {
  margin-bottom: 25px;
}

.link {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 18px;
  margin: 20px 0;
}

.link:hover a {
  text-decoration: underline;
}

.nodecoration {
  composes: link;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.footer {
  margin-top: 60px;
}