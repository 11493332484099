.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 46px);
}

.editable {
  height: 100%;
  margin: 0 0 6px 0;
  overflow: auto;
}

.editable p {
  margin: 0;
}

.tools {
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin: 0 6px 0 0;
}

.icon:last-child {
  margin: 0;
}