.root {
  max-width: 425px;
  margin: 0 auto;
}

.whats-next {
  margin-bottom: 25px;
}

.steps {
  margin-bottom: 40px;
}

.step {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.icon {
  color: var(--gray-d);
  height: 28px;
  width: 28px;
  min-width: 28px;
  margin-right: 20px;
}

.call-icon {
  height: 32px;
  width: 32px;
  min-width: 32px;

  margin-right: 16px;
}

.check-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.check-circle {
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--pri-03);
}

.check {
  position: absolute;
  color: var(--pri-01);

  height: 34px;
  width: 34px;
  left: 8px;
  top: 8px;
}