.root {
  padding: 50px 0;
}

@media (min-width: 1100px) {
  .root {
    padding: 50px 0 75px 0;
  }
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';
  margin-bottom: 25px;
}

@media (min-width: 800px) {
  .headline {
    width: 500px;
  }
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
  margin-bottom: 50px;
}

@media (min-width: 800px) {
  .copy {
    width: 675px;
  }
}

@media (min-width: 1100px) {
  .copy {
    width: 600px;
    margin-bottom: 30px;
  }
}

.content {
  text-align: center;
  position: relative;
}

.img-container {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
}

.img {
  display: none;
  max-width: 100%;
}

.img-mobile {
  max-width: 100%;
}

.img-background {
  display: none;
}


@media (min-width: 800px) {
  .img-container {
    width: 700px;
  }

  .img {
    display: block;
  }

  .img-mobile {
    display: none;
  }
}

@media (min-width: 1100px) {
  .img-container {
    width: 700px;
  }

  .img {
    margin-top: 0;
  }

  .img-background {
    display: block;
    top: -50px;
    position: absolute;
    width: 86%;
    left: 7%;
  }
}
