.root {
  --cell-height: 24px;
  --footer-height: 45px;

  height: 100%;
  max-height: 100%;
  font-size: 13px;
  color: var(--black-l);

  overflow: auto;
  position: relative;

  border-top: 1px solid var(--gray);
  border-left: 1px solid var(--gray);
}

.table {
  height: 100%;
  position: relative;
  border-collapse: collapse;
}

.table tbody {
  height: calc(100% - var(--footer-height));
}

.table th, .table td {
  height: var(--cell-height);
  box-sizing: border-box;
}

.table th .cell {
  justify-content: center;
}

.cell {
  display: flex;
  padding: 3px;
  align-items: center;
}

.align-right {
  justify-content: flex-end;
}

.table th {
  background-color: var(--gray-xl);

  border-right: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.table td {
  background-color: var(--pri-01);
  border-right: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);

  width: 100%;
  box-sizing: border-box;

  overflow: hidden;
  white-space: nowrap;
}

.table tbody th {
  position: sticky;
  left: 0;
}

.table thead th {
  position: sticky;
  top: 0;
}

.table thead th:first-child,
.table tr th:first-child
{
  left: 0;
}

.table thead th:first-child {
  z-index: 1;
}

.link {
  text-decoration: underline;
  color: var(--pri-02);
}

.resizer {
  display: inline-block;
  background: var(--sec-05);
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  touch-action: none;

  opacity: 0;
}

.resizer:hover, .resizer.resizing {
  opacity: 1;
}

.limit {
  padding: 3px;
  font-family: var(--font-semibold);
}