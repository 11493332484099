.root {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.root a {
  color: var(--pri-02);
  position: relative;
}

.root a:hover {
  cursor: pointer;
}

.root a::after {
  position: absolute;
  width: 0%;
  background-color: var(--pri-02);
  opacity: 0.85;
  height: 2px;
  bottom: -6px;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s ease 0s;
  display: block;
  z-index: 1;
}

.root a:hover::after {
  width: 100%;
}

.copy {
  width: 100%;
  font-size: 22px;
  font-family: var(--font-reg);
  color: var(--acc-01);
}

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: auto;
  width: 100%;
}

.line {
  position: relative;
  font-size: 20px;
  color: white;
  background: var(--gray-l);
  width: 1px;
}

.social {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 350px;
  margin: 5% 0;
}

.socialWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 3 1;

}

.terms {
  grid-column: 1 / 4;
  grid-row: 5;
  text-align: center;
  width: 250px;
  line-height: 1.6;
  font-size: 18px;
}

.linkedin {
  width: 275px;
  margin-top: 140px;
}

.linkedin:hover {
  cursor: pointer;
}

.logo {
  position: absolute;
  top: 20px;
  left: 0;
}

.img {
  width: 75px;
}

.header {
  font-family: var(--font-semibold);
  font-size: 36px;
  color: var(--black-l);
}

.form {
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 5% 0;
  margin-right: 20px;
}

.input {
  font-size: 20px;
  border: none;
  border-bottom: 2px solid var(--gray-l);
  font-family: var(--font-reg);
  margin-top: 70px;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.input::placeholder {
  color: var(--gray);
}

.link {
  position: relative;
  padding: 15px 0;
  font-family: var(--font-bold);
  text-align: right;
  color: var(--pri-02);
  letter-spacing: -0.25px;
}

.forgot {
  cursor: pointer;
  display: inline-block;
}

.submit {
  margin-top: 30px;
  text-align: left;
}

.notify {
  position: relative;
  text-align: left;
  height: 15px;
}

.btn {
  background-color: var(--pri-02);
  color: var(--pri-01);
  width: 270px;
  height: 50px;
  border-radius: 8px;
  font-family: var(--font-semibold);
  font-size: 18px;
}

.error {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-family: var(--font-helv);
  color: var(--pri-04);
}

.footer {
  margin-top: 40px;
}