.root {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

.btn {
  font-family: var(--font-02);
  font-size: 20px;
  font-weight: 600;
  border: 2px solid var(--pri-02);
  border-radius: 10px;
  width: 150px;
  padding: 12px;
  margin: 15px;
}

.submit:disabled {
  background-color: var(--gray-l);
  border: 2px solid var(--gray-l);
  cursor: default;
}

.cancel {
  color: var(--pri-02);
}

.submit {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.submit:hover:enabled {
  background-color: var(--sec-02);
  border: 2px solid var(--sec-02);
  transition: all 0.5s ease;
}
