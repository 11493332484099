.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty {
  text-align: center;
  padding: 35px 0;
}

.text {
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.filter {
  margin-left: 15px;
}

.jump-to {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  margin-left: 15px;
}

.jump-to.open, .jump-to:hover {
  background-color: var(--gray-xl);
}

.chevron {
  margin-left: 8px;

  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid var(--gray-d);
}