.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 22px;
  text-align: center;
  margin-bottom: 40px;
}

.fail {
  width: 360px;
  border: 3px solid var(--black-l);
  border-radius: 20px;
  padding: 15px;
  color: var(--black-l);
  font-family: var(--font-bold);
}

.pass {
  position: relative;
  width: 360px;
  border: 3px solid var(--sec-03);
  border-radius: 20px;
  padding: 15px;
  color: var(--sec-03);
  font-family: var(--font-bold);
}

.pass:hover {
  color: var(--pri-01);
  background-color: var(--sec-03);
  cursor: pointer;
  transition: 0.3s ease all;
}

.pass span {
  position: absolute;
  right: 15px;
  letter-spacing: -1px;
}
