.root {
  max-width: 750px;
}

.wrap {
  margin-top: 10px;
}

.accordion {
  padding: 0 var(--gutter-width);
}

@media (min-width: 900px) {
  .accordion {
    padding: 0;
  }
}

.cancel {
  composes: nofill from 'css/button.css';
}

.confirm {
  composes: fill from 'css/button.css';
}

.btn {
  min-width: 130px;
}