@value 1100 from 'vars';

.root {
  max-width: 1260px;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  overflow-y: hidden;
}

@media 1100 {
  .root {
    margin: 0 auto;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 100%;
}

@media 1100 {
  .wrap {
    flex-direction: row;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: relative;
  width: 100%;
}

.hidden {
  composes: panel;
  display: none;
}

.visible {
  composes: panel;
  display: flex;
}

@media 1100 {
  .hidden {
    display: flex;
  }
}

.shadow {
  border: 1px solid var(--sec-01);
  /* border-radius: 6px; */
  overflow: hidden;
}

.header {
  position: relative;
  border-bottom: 1px solid var(--sec-01);
  width: 100%;
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.content {
  /* display: flex; */
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - 50px);
}

.edit {
  stroke-width: 1.5;
  color: var(--pri-02);
}

.title {
  font-size: 24px;
  font-family: var(--font-semibold);
}

.btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item {
  padding: 15px;
}

.field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--gray-l);
}

.textarea {
  height: 50px;
  width: 100%;
  font-size: 18px;
  border: none;
  outline: none;
  resize: none;
  border-radius: 0;
  font-family: var(--font-reg);
  color: var(--black-l);
  padding: 0 10px;
}

.textarea::placeholder {
  color: var(--gray);
}