.root {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  height: 100%;
}

.wrap {
  display: flex;
  flex-direction: column-reverse;
  min-height: max(800px, calc(100% - 40px));
  margin: 20px 0;
  position: relative;
}

@media (min-width: 900px) {
  .wrap {
    flex-direction: row;
    justify-content: flex-end;
    min-height: auto;
    height: calc(100vh - var(--navbar-height) - 40px);
  }
}

.section {
  width: 100%;
  min-height: 500px;
  box-shadow: none;
}

.section:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (min-width: 900px) {
  .section {
    border-radius: 5px;
    width: 460px;
    border: 1px solid var(--sec-01);
  }

  .section:first-child {
    margin-right: 15px;
    overflow-y: auto;
  }

  .section:nth-child(2) {
    display: block;
    height: auto;
    overflow-y: auto;
  }
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  height: 70px;
}

.section:first-child .header {
  justify-content: space-between;
  border-bottom: 1px solid var(--sec-01);
}

.h2 {
  color: var(--black-l);
  font-size: 22px;
  line-height: 35px;
  font-family: var(--font-semibold);
  width: 100%;
}

.callsheader {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  border-bottom: 1px solid var(--sec-01);
  color: var(--black-l);
  font-size: 24px;
  font-family: var(--font-bold);
  padding: 20px;
  align-items: center;
}

.btn {
  width: 140px;
  border-color: var(--gray-l);
  color: var(--gray-d);
}

@media (min-width: 900px) {
  .btn:hover {
    background-color: var(--sec-01);
    border-color: var(--gray);
  }
}

.completed-label {
  color: var(--black-l);
  font-size: 22px;
  font-family: var(--font-semibold);
  margin: 0;
}

.border {
  border-bottom: 4px solid var(--pri-02);
}

.nocalls {
  padding: 20px;
}