.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}

.url {
  display: flex;
  align-items: center;
  margin: 0 0 6px 0;
}

.url svg {
  color: var(--gray-d);
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
}

.details {
  font-size: 14px;
  word-break: break-word;
}

.pin {
  display: flex;
  align-items: center;
  margin: 0 0 6px 0;
}

.url:last-child,
.pin:last-child {
  margin: 0;
}

.pin svg {
  color: var(--gray-d);
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
}
