:root {
  --border-color: #f5f5f5;
  --focused-border-color: #979797;
  --color: #323232;
  --focused-color: #adadad;
  --error-color: var(--pri-04);
}

:global(.submit-btn) {
  font-family: var(--font-bold) !important;
  font-size: 16px !important;
  text-align: center;
  cursor: pointer;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color .18s ease-in-out, border .12s ease-in, border-color .12s ease-in, color .12s ease-in;
  border: 2px solid #1db955 !important;
  background-color: #1db955 !important;
  color: #fff;
  padding: 10px !important;
  border-radius: 5px !important;
}

:global(.submit-btn:hover) {
  border: 2px solid #04a038 !important;
  background-color: #04a038 !important;
}

:global(.hs-input) {
  width: 100%;
  border: 1.5px solid var(--gray-l);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: var(--font-reg);
  font-size: 16px;
  line-height: 19px;
  padding: 15px 12px;
  color: var(--color);
}
:global(.hs-input:focus) {
  border: 1.5px solid var(--focused-border-color);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
}

:global(.hs-input.error) {
  border-color: var(--error-color);
}

:global(.hs-error-msgs) {
  list-style: none;
  padding-left: 0px;
  color: var(--error-color);
  font-size: 12px;
}

:global(.hs-form-field) {
  position: relative;
  margin-bottom: 30px;
}