.root {
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 25px;
}

.header {
  margin: 20px auto;
  max-width: 1080px;
}

.img {
  width: 75px;
}

.wrap {
  margin: 0 auto;
  padding: 0 0 60px;
}

@media(max-width: 425px){
  .wrap {
    padding: 0 15px 60px 15px;
  }
}

@media(min-width: 425px){
  .wrap {
    width: 560px;
  }
}

.h2 {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 28px;
  padding-left: 25px;
  margin-bottom: 15px;
}

.rules {
  padding-top: 20px;
  padding-bottom: 30px;
}

.ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.title {
  font-family: var(--font-01);
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.headline {
  margin: 20px 0;
}

.scroll {
  overflow: auto;
  overflow-y: auto;
  max-height: 72vh;
  margin-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
}

@media(max-width: 425px){
  .scroll {
    max-height: 65vh;
  }
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.4);
}

.description {
  font-family: var(--font-01);
  font-size: 16px;
  color: var(--black-l);
}

.subjects {
}


.subject {
  font-size: 23px;
  font-family: var(--font-bold);
  color: var(--pri-02);
  padding-left: 15px;
  cursor: pointer;
}

.subject:hover {
  text-decoration: underline;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  font-family: var(--font-bold);
  font-size: 20px;
  font-weight: 600;
  border: 2px solid var(--pri-02);
  border-radius: 10px;
  width: 150px;
  padding: 12px;
  margin: 15px;
}

.cancel {
  color: var(--pri-02);
}

.next {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.next:hover {
  background-color: var(--sec-02);
  border: 2px solid var(--sec-02);
  transition: all 0.5s ease;
}
