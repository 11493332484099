.root {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}

@media (min-width: 450px) {
  .root {
    padding: 20px;
  }
}

.name {
  font-family: var(--font-semibold);
  font-size: 20px;
  color: var(--black-l);

  margin-bottom: 15px;
}

.name:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.details {
  margin-bottom: 15px;
}

.accordion {
  margin-bottom: 15px;
}

.link {
  color: var(--pri-02);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.label {
  margin-bottom: 15px;
  font-family: var(--font-semibold);
}

.question {
  border-bottom: 1px solid var(--gray-xl);
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
}

.question:last-of-type {
  border-bottom: none;
}

.flag-btn {
  margin-left: 10px;
  padding: 8px;
  min-width: 65px;
  height: 40px;
  background-color: var(--pri-01);
}

@media (min-width: 425px) {
  .flag-btn {
    min-width: 80px;
  }
}

.unverified {
  color: var(--pri-04);
  font-family: var(--font-semibold);
}

.response-item {
  width: 100%;
}

.answer {
  composes: offset-left from 'scenes/surveys/style.css';

  margin-top: 10px;
  width: 85%;
}

.hidden {
  background-color: var(--gray-xl);  
}