.input {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--gray);
  border-radius: 4px;
  padding: 4px;
  text-align: right;
}

.input:hover {
  /* border: 2px solid var(--gray-d); */
}

.input:focus {
  border: 2px solid var(--pri-02);
}

.invalid {
  border: 2px solid var(--pri-04);
}

.theme {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--gray);
  border-radius: 4px;
  padding: 4px;
  text-align: right;
}