.items {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /* align-items: center; */
  flex-wrap: wrap;
}

.columns {
  composes: items;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.staggered {
  composes: items;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.sectors .tag,
.columns .tag,
.selections .tag {
  width: 160px;
  font-size: 11px;
  margin-right: 15px;
}

.selections {
  composes: items;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
}

@media (min-width: 800px) {
  .columns {
    justify-content: space-between;
  }

  .sectors {
    composes: selections;
    justify-content: space-between;
  }

  .sectors .tag,
  .columns .tag {
    width: 190px;
    margin-right: 0;
  }

  .sectors .tag,
  .columns .tag,
  .selections .tag,
  .staggered .tag {
    font-size: 14px;
  }
}

.item {
  margin: 5px 0 10px 0;
}

.staggered .item {
    margin-right: 5px;
}

.tag {
  border-radius: 20px;
  height: 36px;
  padding: 6px 20px;
  font-family: var(--font-bold);
  font-size: 14px;
  background-color: var(--pri-01);
  color: var(--gray-d);
  border: 1px solid var(--gray-l);
  transition: all 0.1s ease-in;
}

.tag:hover {
  background-color: var(--pri-01);
  color: var(--pri-02);
  border: 1px solid var(--pri-02);
}

.selected {
  composes: tag;
  background-color: var(--blue-l);
  color: var(--pri-02);
  border: 1px solid var(--pri-02);

  position: relative;
}

.selected:hover {
  background-color: var(--blue-l);
  color: var(--pri-02);
  border: 1px solid var(--pri-02);
}

.phantom {
  composes: tag;
  visibility: hidden;
  display: none;
}

@media (min-width: 800px) {
  .phantom {
    display: inherit;
  }
}

.name {

}

.x-container {
  display: none;  
}

.item:hover .x-container {
    border-left: 2px solid var(--pri-01);
    height: 100%;
    cursor: pointer;
    position: absolute;

    top: 0;
    right: 10px;
    background-color: var(--sec-05);

    display: flex;
    align-items: center;
}

.x {
    padding-left: 10px;
    padding-right: 2px;
}