.root {
  padding: 0 15px;
}

.row {
  padding: 15px 0;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}

.footer {
  margin-top: 100px;
}

.btn {
  composes: brick from 'css/button.css';
}

.project {
  display: flex;
  align-items: center;
}

.clear {
  color: var(--gray-m);
  cursor: pointer;
  margin-left: 10px;
}