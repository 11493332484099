.label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 14px;
}

.label-error {
  color: var(--pri-04);
}

.star {
  color: var(--sec-02);
}

.input-container {
  position: relative;
}

.input,
.dropdown {
  composes: input from 'static/css/input.css';
  color: var(--black);
  border-bottom: 2px solid var(--sec-01);
  border-radius: 0;
  font-size: 14px;
  font-family: var(--font-semibold);
  line-height: 28px;
}

.input:focus,
.dropdown:focus {
  border-bottom: 2px solid var(--pri-02);
}

.row:not(:last-of-type) {
  margin: 0 0 16px 0;
}

.option {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-bold);
}

.option span {
  margin: 0 10px 0 0;
}