.items {
  --vertical-gap: 35px;
  --horizontal-gap: 40px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: calc(-1 * var(--vertical-gap)) 0 0 calc(-1 * var(--horizontal-gap));
}

.items > * {
  margin: var(--vertical-gap) 0 0 var(--horizontal-gap);
}

.section {
  min-height: 220px;
  padding-bottom: 100px;
}