.root {
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.disabled {
  cursor: unset;
}

.target {
  background-color: #D8F5E1;
}

.competitor {
  background-color: #FFD1CE;
}

.eval-criteria {
  background-color: #FFF1B6;
}

.partnership-criteria {
  background-color: #FFE3D0;
}

.target-role {
  background-color: #F4D5FC;
}

.category {
  background-color: #D1DEFC;
}