.root {
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 25px;
}

@media(max-width: 425px){
  .root {
    max-width: 350px;
  }
}

.header {
  margin: 20px auto;
}

.img {
  width: 75px;
}

.header {
  margin: 0 auto;
  max-width: 700px;

  padding-left: 25px;
  margin-bottom: 15px;
}

@media(max-width: 425px){
  .header {
    max-width: 350px;
  }
}

.h2 {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 28px;
  margin-bottom: 15px;
}

.subheader {
  color: var(--gray-d);
  font-size: 18px;
}