.body {
  margin: 0 25px;
}

.message {
  margin-bottom: 10px;
}

.items {

}

.item {
  margin-left: 10px;
}

.footer {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  font-size: 15px;
  padding: 8px;
}

.actions {
  margin-left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions .btn:first-child {
  margin-right: 10px;
}