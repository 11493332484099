.row {
  margin-top: 15px;
}

@media (min-width: 800px) {
  .row, .header-row {
    display: flex;
    align-items: center;
  }
}

.header {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
}

.choice {
  margin-top: 20px;
}

@media (min-width: 800px) {
  .choice {
    width: 240px;
    min-width: 240px;
    margin: 0 20px;
  }
}

.action {
  width: 150px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .action {
    margin-top: 0;
  }
}

.ordinal {
  margin-top: 15px;
  width: 80px;
}

@media (min-width: 800px) {
  .ordinal {
    margin-left: 10px;
    margin-top: 0;
  }
}

.condition {
  margin-top: 15px;
  width: 115px;
}

@media (min-width: 800px) {
  .condition {
    margin-top: 0;
  }
}

.remove {
  color: var(--pri-04);
  cursor: pointer;
  margin-top: 15px;

  width: 22px;
  height: 22px;
  display: flex;
  flex-shrink: 0;
}

@media (min-width: 800px) {
  .remove {
    margin-left: 10px;
    margin-top: 0;
  }
}

.add {
  margin-top: 15px;
}