.root {
  position: relative;
  padding: 20px 0;
}

.question {

}

.question-text {
  margin-right: 50px;
}

.question-text[contenteditable="false"] p {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 16px;
  line-height: unset;
}

.menu {
  position: absolute;
  top: 10px;
  right: 0;
}