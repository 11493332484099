.root {

}

.link {

}

.link:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}