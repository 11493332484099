.root {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--gray-xl);
}

.btn {
  width: 25px;
  padding: 0 5px;
  position: absolute;
  left: calc(50% - 15px);
  z-index: 1;
  cursor: pointer;
  background-color: var(--pri-01);
  color: var(--gray-d);
}

.question-btn {
  color: var(--pri-02);
}

@media (min-width: 800px) {
  .question-btn {
    display: none;
  }

  .question:hover .question-btn {
    display: block;
    animation: grow .3s;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}