.root {
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.checkbox {
  display: flex;
  align-items: center;
  align-self: center;
  color: var(--gray-d);
  font-family: var(--font-helv), var(--font-reg), -apple-system, BlinkMacSystemFont, sans-serif;
}

.dates {
  display: flex;
  align-items: center;
}

.dates .select:not(:first-child) {
  margin-left: 10px;
}

.ranges {
  display: flex;
  margin-bottom: 15px;
}

.end {
  margin-left: 40px;
  width: 110px;
}

.select {
  width: 50px;
  font-size: 16px;
  border-color: transparent;
  background-color: var(--pri-01);
  font-family: var(--font-reg);
  line-height: 30px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid var(--pri-02);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.present {
  composes: select;
  width: 80px;
}

.box:after {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: 1px solid var(--black-l);
  border-radius: 5px;
}

.previous,
.current {
  composes: box;
  position: relative;
  height: 20px;
  width: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-right: 10px;
}

.previous:after {
  content: '';
  background-color: var(--pri-01);
}

.current:after {
  content: '✓';
  color: var(--pri-01);
  background-color: var(--sec-05);
}

.text {
  font-family: var(--font-reg);
}