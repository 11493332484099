.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--pri-03);
  cursor: pointer;
}

.root:hover .check {
  background-color: var(--pri-03);
  color: var(--pri-01);
}

.check {
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s ease;
  color: var(--pri-03);
  border: 2px solid var(--pri-03);
}