.root {

}

.row {
  margin: 0 0 14px 0;
}

.label {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 14px;
  margin: 0 0 8px 0;
}

.methods {
  display: flex;
}

.method {
  cursor: pointer;
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--pri-02);
  border-radius: 6px;
  padding: 6px 20px;
  transition: 0.3s;
}

.methods > div {
  margin: 0 4px 0 0;
}

.active,
.method:hover {
  background-color: #E2ECFD;
}