.root {
  margin: 20px 0 0 0;
  min-height: 154px;
}

.header {
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.method {
  color: var(--gray-d);
  font-size: 14px;
  margin: 0 0 20px 0;
}

.actions {
  margin: 24px 0 0 0;
}

.actions > button,
.actions > a {
  margin: 0 20px 0 0;
}

.verify {
  font-size: 15px;
  padding: 0;
  height: 34px;
  width: 110px;
}

.change {
  font-size: 15px;
  padding: 0;
  height: 34px;
  width: 110px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
