.body-base {
  align-items: center;
  display: flex;
  padding: 5px 10px;
}

.body-action {
  align-items: center;
  display: flex;
  padding: 5px 5px 5px 10px;

  --hover-color: var(--pri-02);
}

.action {
  color: rgb(92, 153, 255);
  display: inline-flex;
  margin: 0 0 0 4px;
}

.body-action:not(.disabled) .action {
  cursor: pointer;
}

.body-action:not(.disabled) .action:hover {
  color: var(--hover-color);
}

.action svg {
  width: 19px;
  height: 19px;
}

.clickable:not(.disabled):hover .action svg {
  color: var(--hover-color);
}

.body-action:not(.disabled) .action:hover svg {
  color: var(--hover-color);
}

.clickable .action svg,
.body-action .action svg
{
  transition: background-color 200ms ease-in-out,
              border 140ms ease-in,
              border-color 140ms ease-in,
              color 140ms ease-in;
}