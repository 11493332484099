.icon-screen,
.icon-screen-off {
  composes: icon from './Shared.css';
  border: 1px solid var(--gray-l);
}

.icon-screen-off {
  color: var(--pri-01);
  background-color: var(--pri-04);
  border-color: var(--pri-04);
}

@media (min-width: 800px) {
  .icon-screen:hover {
    background-color: var(--gray-xl);
  }

  .icon-screen-off:hover {
    background-color: var(--sec-04);
  }
}

.icon-screen svg,
.icon-screen-off svg {
  height: 32px;
  width: 32px;
}