.root {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid var(--gray-xl);

  color: var(--gray-d);
  font-size: 14px;
}

.avatar {
  margin-right: 15px;
}

.details {
  color: var(--gray-d);
  font-size: 14px;
  width: 100%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-family: var(--font-semibold);
}

.name:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.title {
  margin-top: 5px;
}