.root {
  composes: content from '../../style.css';
}

h1.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 28px;
  line-height: 54px;
}

.section {
  border: 1px solid var(--sec-01);
  border-radius: 10px;
  padding: 14px 20px;
  margin-bottom: 30px;
  min-height: 140px;
}

.section-short {
  composes: section;
  min-height: 110px;
}

.section:last-child {
  margin-bottom: 50px;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h2.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 16px;
}

.stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 103px;
}

.field {
  composes: input-container from 'scenes/settings/style/AccountSettings.css';
  margin: 0;
  padding: 0;
  border: none;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
}

.item {
  color: #555555;
  align-items: center;
  font-size: 18px;
  margin-bottom: 25px;
}

.rate {
  font-family: var(--font-reg);
  font-size: 18px;
  line-height: 32px;
  display: inline-flex;
}

.label {
  width: 120px;
  font-size: 18px;
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.dollar {
  composes: rate;
}

.rate-container {
  display: flex;
  align-items: center;
}

.input-container {
  width: 125px;
}

.rate-x {
  margin-left: 5px;
}

.disclaimer {
  color: var(--gray-d);
  font-size: 14px;
  font-style: italic;
  margin-top: 6px;
}