.root {
  height: 100%;
}

.root .actions {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

.root:hover .actions {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.actions {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 6px 0 0;
}

.fs {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: var(--gray-xl);
  cursor: pointer;
  height: 35px;
  width: 35px;
  transition: all .2s ease-in-out;
}

.fs svg {
  height: 35px;
  width: 35px;
}

.fs:hover {
  background: rgba(0, 0, 0, 0.5);
  transform: scale(1.2);
}