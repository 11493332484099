.root {
  margin: 0 auto;
  display: flex;
  padding: 0 10px;
}

.primary {

}

.sidebar {
  display: none;
}

@media (min-width: 900px) {
  .primary {
    width: 900px;
    margin-left: auto;
    margin-right: 60px;
  }

  .sidebar {
    display: block;
    width: 300px;
    margin-right: auto;
    margin-top: 50px;
  }
}

.header {
  background-color: #fff;
  position: sticky;
  top: var(--navbar-height);
  padding: 20px 10px 15px 2px;
  z-index: 2;
}

.back {
  font-family: var(--font-semibold);
  color: var(--pri-02);
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  width: 60px;
}

.back:before {
  content: '< ';
}

.sticky {
  position: sticky;
  top: calc(var(--navbar-height) + 53px);
}