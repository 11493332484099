.section {
  border: 1px solid var(--sec-01);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.section-title {
  font-family: var(--font-semibold);
  font-size: 20px;
}

@media (min-width: 500px) {
  .header {
    display: flex;
    align-items: center;
  }
}

.project {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
}

.version {
  margin-left: 15px;
}

.rating {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.details {
  margin-bottom: 20px;
}

.actions {
  display: none;
}

@media (min-width: 1100px) {
  .actions {
    display: block;
  }
}

.user {
  margin-bottom: 15px;
}

.avatar {
  width: 95px;
  height: 95px;
  cursor: default;
  margin-right: 30px;
}

.avatar-wrap {
  display: inline-flex;
}

a .avatar {
  cursor: pointer
}

a .username {
  cursor: pointer;
}

a .username:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.username {
  font-family: var(--font-semibold);
  font-size: 20px;
}

.user-link-container {
  margin-bottom: 8px;
  display: inline-block;
}

.main-header {
  margin: 10px 0;
}

@media (min-width: 500px) {
  .main-header {
    flex-grow: 1;
    align-self: start;
    margin: 0;
    margin-right: 10px;
  }
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.label {
  font-family: var(--font-semibold);
  margin-right: 5px;
}

.responses-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.version-container {
  display: flex;
  align-items: center;
}

.completed-on {
  display: none;
}

@media (min-width: 800px) {
  .completed-on {
    display: block;
  }
}

.work-experience-header {
  justify-content: space-between;
}

.work-experience-link {
  color: var(--pri-02);
  font-family: var(--font-bold);
  display: flex;
  justify-content: center;
  line-height: 22px;
  font-size: 14px;
}