.loading {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-text {
  font-size: 36px;
  text-align: center;
  margin-top: 40px;
}

.error-msg {
  composes: error-msg;
}

.missing-link {
  composes: error-msg;
  color: var(--pri-04);
}