html {
  min-height: 100%;
}

body {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-reg);
  color: var(--black);
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  padding: 0;
  color: inherit;
  -webkit-appearance: none;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
}

button {
  user-select: none;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button,
input:focus,
select:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
}

a {
  color: inherit;
  text-decoration: none;
}

select {
  -webkit-appearance: none;
}

h1, h2 {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: unset;

  color: var(--black);
  font-family: var(--font-bold);
  font-size: 24px;
}

textarea {
  appearance: none;
}