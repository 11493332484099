.root {
  padding: 12px 15px;
  color: var(--gray-d);
}

.root ~ .root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {
  display: flex;
  justify-content: space-between;
}

.main {
  margin-right: 15px;
}

.header {
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 14px;
  margin-bottom: 5px;
}

.description {
  font-size: 14px;
  font-family: var(--font-reg);
}

.time {
  font-size: 13px;
  font-family: var(--font-reg);
}

.join {
  margin: 10px 0 0;
  height: 40px;
  padding: 0 40px;
}

.menu {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: flex-end;
}