.root {
  display: flex;
  align-items: center;
}

.bullet {
  font-family: var(--font-semibold);
  font-size: 18px;
  color: var(--gray);
  margin-right: 5px;
  width: 35px;
  min-width: 35px;
}

.value {
  word-break: break-word;
}