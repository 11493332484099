.wrap {
}

.terms {
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 14px;
}

.terms a {
  text-decoration: underline;
  color: var(--pri-02);
}

.paragraph {
  margin-bottom: 20px;
}

.header {
  font-size: 14px;
  font-family: var(--font-bold);
  line-height: 24px;
}

.inline {
  display: inline-block;
  font-size: 14px;
  font-family: var(--font-bold);
  margin-right: 5px;
}

.list {
  margin: 4px 0 0 0;
  padding-left: 16px;
}

.list li {
  margin-bottom: 10px;
}

.list li:last-child {
  margin-bottom: 0;
}

.bold {
  font-family: var(--font-bold);
}

.upcase {
  text-transform: uppercase;
}

@media (min-width: 600px) {
  .terms,
  .header,
  .inline {
    font-size: 16px;
  }

  .list {
    padding-left: 18px;
  }

  .list li {
    padding-left: 22px;
  }
}