.root {
  position: absolute;
  background-color: var(--pri-01);
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
}

.root:after,
.root:before {
	position: absolute;
	left: auto;
	top: -14px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	pointer-events: none;
}

.root:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: var(--pri-01);
	border-width: 14px;
	margin-top: -14px;
}

.root:before {
	border-color: rgba(189, 189, 189, 0);
	border-bottom-color: rgba(0, 0, 0, 0.3);
	border-width: 14px;
	margin-top: -15px;
}

.left {
  composes: root;
}

.left:after,
.left:before {
	left: 40px;
}

.right {
  composes: root;
}

.right:after,
.right:before {
  right: 30px;
}

.header {
  padding: 12px 24px;
  margin: 0;
  font-size: 16px;
  font-family: var(--font-bold);
  background-color: var(--pri-02);
  border-bottom: 1px solid #ebebeb;
  border-radius: 14px 14px 0 0;
  color: var(pri-01);
}

.body {
  padding: 16px 24px 0;
  color: var(--black);
}

.date {
  color: var(--gray-d);
  font-size: 16px;
  font-family: var(--font-bold);
  margin-bottom: 4px;
}

.time {
  font-family: var(--font-reg);
  margin-bottom: 4px;
}

.participant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
}

.name {
  font-size: 15px;
  font-family: var(--font-bold);
  margin-left: 16px;
}

.url:hover {
  text-decoration: underline;
}

.title {
  font-size: 16px;
  font-family: var(--font-bold);
  margin-top: 8px;
}

.callaction {
  width: 150px;
  margin-top: 15px;
}

.actions {
  padding: 10px 24px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.delete {
  color: var(--gray-d);
}

.delete:hover {
  cursor: pointer;
}

.avatar {
  position: relative;
  height: 26px;
}

.status {
  top: 18px;
  right: -5px;
  position: absolute;
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.check {
  composes: status;
  background-color: rgba(5, 183, 119);
}

.rotate {
  composes: status;
  background-color: rgba(238, 193, 0);
}

.cross {
  composes: status;
  background-color: rgba(255, 79, 62);
}

.noteslabel {
  color: var(--gray-d);
  display: inline-block;
  font-size: 11px;
  font-family: var(--font-bold);
}

.notes {
  font-size: 11px;
  margin-top: 6px;
}