.root {
  padding: 20px 0;
}

.base-logic, .response-logic {
  margin-top: 20px;
}

.logic {
  composes: offset-left from 'scenes/surveys/style.css';
}

.text[contenteditable="false"] p {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 16px;
  line-height: unset;
}