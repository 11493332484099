.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.details {
  overflow: hidden;
}

.name {
  font-size: 14px;
  font-family: var(--font-semibold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unbolded {
  font-family: inherit;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
  width: 28px;
}

.video-call-icon {
  composes: icon;
  color: #AB30D5;
}

.voice-call-icon {
  composes: icon;
  color: #5C99FF;
}

.overflow-wrapper {
  max-width: 100%;
}