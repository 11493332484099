.notice {
  color: var(--black);
  font-size: 14px;
  margin: 0 0 20px 0;
}

.bold {
  font-family: var(--font-bold);
}

.deposits {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px 0;
}

.deposit {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.deposit:last-child {
  margin: 0;
}

.label {
  color: var(--black);
  font-size: 15px;
  font-family: var(--font-reg);
  width: 100px;
}

.dollar {
  color: var(--black);
  font-size: 15px;
  margin: 0 2px 0 0;
  height: 19px;
}

.value {
  composes: input from 'static/css/input.css';
  color: var(--black);
  border-bottom: 2px solid var(--sec-01);
  font-size: 15px;
  width: 19px;
  height: 19px;
}

.value:focus {
  border-bottom: 2px solid var(--pri-02);
}

.actions {
  display: flex;
  justify-content: center;
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.error {
  display: flex;
  justify-content: center;
  color: var(--sec-04);
  font-family: var(--font-semibold);
  font-size: 14px;
  margin: 0 0 20px 0;
  height: 18px;
}