.root {
  color: var(--gray-d);
  font-size: 14px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 6px;
}

.file {
  margin-right: 6px;
}

.view {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.download {
  cursor: pointer;
}

@media (min-width: 600px) {
  .download:hover,
  .view:hover {
    color: var(--pri-02);
  }
}