.following {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  width: 125px;
}

.check {
  margin-right: 5px;
}

.follow {
  padding: 7px 10px;
  width: 125px;
}