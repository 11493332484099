
.row {
  margin-bottom: 25px;  
}

.labels {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.least,
.most {
  width: 47%;
}


.settings {
  margin-top: 25px;
}

.attributes,
.sets {
  cursor: pointer;
  width: 75px;
}
