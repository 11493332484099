@value 1100 from 'vars';

.root {
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.wrap {
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

@media 1100 {
  .wrap {
    max-width: 1260px;
  }
}

.main {
  box-sizing: border-box;
  padding: 0 10px;
  margin: 70px auto;
}

@media 1100 {
  .main {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
  }
}

.nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  height: 30px;
}

.devices {
  display: none;
}

@media 1100 {
  .devices {
    display: block;
    max-width: 500px;
  }
}

.header {

}


@media 1100 {
  .header {
    width: 420px;
  }
}

.title {
  font-size: 24px;
  color: var(--black-l);
  text-align: center;
}

@media 1100 {
  .title {
    font-size: 40px;
    text-align: left;
  }
}

.subheader {
  text-align: center;
}

@media 1100 {
  .subheader {
    text-align: left;
  }
}

.h5 {
  font-size: 22px;
  color: var(--black);
  font-family: var(--font-bold);
}

.col {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  max-width: 550px;
}

.col:nth-of-type(1) {
  margin-bottom: 55px;
}

@media 1100 {
  .col {
    padding: 0;
  }

  .col:nth-of-type(1) {
    margin-bottom: 0;
    padding-right: 5px;
  }

  .col:nth-of-type(2) {
    margin-bottom: 0;
    padding-left: 5px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
}

@media 1100 {
  .form {
    width: 400px;
  }
}

.select {
  font-size: 16px;
}

.row {
  min-height: 85px;
}

.row:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media 1100 {
  .row {
    height: 85px;
  }
}

.btn {
  width: 100%;
  height: 50px;
}

.label {
  font-size: 17px;
  color: var(--gray-d);
  font-family: var(--font-bold);
  margin-bottom: 5px;
}

.error {
  color: var(--pri-04);
  font-size: 15px;
  font-family: var(--font-semibold);
  margin-left: 5px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media 1100 {
  .content {
    height: 470px;
  }
}

.root ul {
  list-style: none;
  padding-left: 0;
}

.ul {
  padding-top: 30px;
  margin: 0 auto;
}

@media 1100 {
  .ul {
    margin: 0;
  }
}

.li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.bullet {
  padding-right: 20px;
}

.bullet::before {
  content: '•';
  font-size: 28px;
  color: var(--black);
}

@media 1100 {
  .li {
    font-size: 18px;
  }
}

.link {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 18px;
}

.link:hover a {
  text-decoration: underline;
}

.completed {
  margin: 20px;
}

.completed > div ~ div {
  margin: 20px 0;
}

.submitted {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
}

.required {
  color: var(--pri-02);
}

.input {
  line-height: 32px;
}

.title {
  font-size: 22px;
  font-family: var(--font-bold);
  margin-bottom: 30px;
  text-align: center;
}