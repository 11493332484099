.root {
  padding: 60px 0;
}

@media (min-width: 1100px) {
  .root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.headline {
  composes: headline from '@/access/website/screens/Main/style.css';
  margin-bottom: 25px;
}

@media (min-width: 800px) {
  .headline {
    width: 360px;
  }
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
}

@media (min-width: 800px) {
  .copy {
    width: 515px;
  }
}

@media (min-width: 1100px) {
  .copy {
    width: 425px;
  }
}

.img-container {
  text-align: center;
}

.img {
  display: none;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.img-mobile {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 30px;
}

@media (min-width: 800px) {
  .img {
    display: block;
    margin-top: 30px;
    max-width: 700px;
  }

  .img-mobile {
    display: none;
  }
}

@media (min-width: 1100px) {
  .img {
    margin-top: 0;
  }
}
