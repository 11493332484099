.root {
}

.header {
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
}


.subheader {
  font-family: var(--font-reg);
  font-size: 15px;
  text-align: center;
  line-height: 34px;
}

.actions {
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
}

.sync {
  width: 150px;
}

.image {
  width: 210px;
  height: 74px;
}