@value 800, 1200 from 'vars';

.root {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  height: 75px;
  min-height: 75px;
}

.details {
  margin-left: 10px;
}

.name {
  margin-bottom: 10px;
  font-family: var(--font-bold);
  font-size: 14px;
}

@media 800 {
  .name {
    font-size: 18px;
  }
}

.link {
  color: var(--pri-02);
  font-size: 14px;
}

@media 800 {
  .link {
    font-size: 16px;
  }
}

.link::after {
  margin-left: 5px;
  content: '>';
}