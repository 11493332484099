.root {
  margin: 0 auto;
  max-width: 475px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 30px;
  height: 70vh;
}

.main,
.footer {
  text-align: left;
}

.header {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black);
  margin-bottom: 20px;
}

.logo {
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
}

.email {
  font-family: var(--font-bold);
  color: var(--pri-02);
}

.resend {
  cursor: pointer;
  color: var(--pri-02);
  text-decoration: underline;
}

.subtext {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
  margin-bottom: 4px;
}

.subtext:last-child {
  margin-bottom: 0;
}

.message {
  margin-bottom: 20px;
}

.message:last-child {
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .root {
    max-width: 600px;
  }
}